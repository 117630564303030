<template>
  <div>
    <v-dialog v-model="ImportingProgressDialog" fullscreen="">
        <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">
              <v-container bg fill-height grid-list-md text-xs-center>
              <v-layout row wrap align-center>
              <v-card-text class="justify-center">
                <h2 class="center">Importing Records</h2><br>
                Now Importing - 
                          <div class="red--text">
                          <p>{{Importcount}}</p>
                          </div>
                <v-layout class="justify-center">
                  <div class="loader"></div>
                  </v-layout>
                  <v-layout row class="justify-center mx-3 my-3">
                      <progress id="importprogressbar">
                      </progress>
                  </v-layout>
              </v-card-text>
              </v-layout>
              </v-container>
        </v-card>
      </v-dialog>
    <!-- style="max-height:350px;" -->
    <v-navigation-drawer  v-if="TableEditActive" src="@/assets/QuoteBG2.jpeg" v-model="EditingTable" app right width="400">
    <v-card>
                  <v-card-title>
                      Manage Headers<v-spacer></v-spacer><v-btn @click="EditingTable = !EditingTable" icon><v-icon>mdi-close</v-icon></v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-tabs v-model="HeadersTab">
                      <v-tab>Existing Fields</v-tab>
                      <v-tab-item >
                        <v-list dense>
                          <v-list-item>
                            <v-text-field v-model="FieldSearch" label="Search"/>
                          </v-list-item>
                          <v-list-item class="background">
                          <v-virtual-scroll 
                            style="overflow-x: hidden;padding:4px;"                                  
                            :items="PotentialHeaderFields"
                            :item-height="35"
                            :height="140"
                                >
                                <template v-slot:default="{ item }">
                                  <v-list-item >
                                    <v-list-item-action>
                                      <v-checkbox class="tinylabel" @change="UpdateHeaders(item)" dense :disabled="item.Primary" v-model="item.IsHeader"/>
                                    </v-list-item-action>
                                    <v-list-item-content style="font-size:14px;">
                                      {{item.DisplayName}}
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                          </v-virtual-scroll>
                          </v-list-item>
                        </v-list>
                      </v-tab-item>
                      <v-tab>New Field</v-tab>
                      <v-tab-item >
                        <v-card>
                        <v-card-title>
                            Add Field
                        </v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item>
                                    <v-text-field v-model="NewField.Name" label="Name"/>
                                </v-list-item>
                                <v-list-item>
                                    <v-select :items="HeaderTypes" label="Type" v-model="NewField.Type" />
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                        <v-card-text v-if="NewField.Type">
                          <FieldConfig @UpdateField="UpdateField" @Onepropemit="Onepropemit" @UpdateRelated="UpdateRelated"
                            :CurrentEntity="CurrentEntity"
                            :SystemEntities="SystemEntities" :field="NewField" :NewField="true" :System="System"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-btn>Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="AddNewField()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                  <v-card-actions v-if="HeadersTab === 0">
                    <v-btn @click="RefreshComponent()" dark color="warning">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn dark color="success" @click="SaveHeaderUpdates()">Save</v-btn>
                  </v-card-actions>
                </v-card>
    </v-navigation-drawer>
    <v-dialog v-model="importresultsdialog" max-width="800px">
        <v-card dark height="100%" width ="100%" class="center secondary">
          <v-img src="@/assets/RABaseBG.jpeg">
          <v-card-title>
            Import Result<v-spacer></v-spacer><v-icon @click="CloseImportresultsdialog()">mdi-close</v-icon>
          </v-card-title>
          <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Successful Imports ({{SuccessfulImportsArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in SuccessfulImportsArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>Partial Failures ({{PartialFailuresArray.length}})</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{header.toUpperCase()}}
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-for="res in PartialFailuresArray" :key="res.itemObjKey">
                        <v-list-item-content v-for="header in ImportHeaders" :key="header.itemObjKey">
                          {{res[header]}}
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer><v-btn href="/DataImports" target="_blank" color="green" dark>View Import Record</v-btn>
          
          </v-card-actions>
          </v-img>
        </v-card>
      </v-dialog>
    <v-dialog v-model="importdialog" max-width="500px">
               <v-card>
                 <v-img
                    width="500"
                    src="@/assets/RABaseBG-Soft.png"
                    ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                      <v-card-title class="mediumoverline white--text">
                        Import {{TableName}}<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">{{TableIcon}}</v-icon>
                      </v-card-title>
                      <v-card-subtitle class="overline white--text">
                      <v-icon  class="actionicon" small color="green">mdi-database-import</v-icon> Import {{TableName}} here
                    </v-card-subtitle>
                    <v-card-subtitle class="overline white--text">
                     <v-icon  class="actionicon" @click="DownloadImportTemplate()" small color="white">mdi-download</v-icon> Download {{TableName}} Template
                    </v-card-subtitle></div>
                 <v-card-text>
                     <v-row>
                       <v-col cols="12" sm="12" md="12" lg="12">
                         <input v-if="!ImportFile"
                                   type="file"
                                   @change="onImportfileSelected($event)"
                                   ref="ImportFileinputter"
                                   id="fileUpload"
                                   >
                         <v-text-field class="FormFields" v-model="Import.Name" label="Import File Name"></v-text-field>
                         
                         
                       </v-col>
                     </v-row>
                   </v-card-text>
                   <v-card-actions class="white documenttypeshadowinvert">                  
                  <v-btn color="orange" dark class="overline" @click="closeImport()">Cancel</v-btn>
                    <v-spacer></v-spacer>
                  <v-btn color="green" dark  class="overline" @click="saveImport()">Save</v-btn>
                </v-card-actions>
                 </v-img>
               </v-card>
             </v-dialog>
              <LookupSubColDialogComponent v-if="SubColLookupDialogActive"
              @DeactivateSubColLookupDialog="DeactivateSubColLookupDialog" :MasterTableObj="MasterTableObj" :tab="tab"
              :CurrentEntity="CurrentEntity" :TableCollectionRef="TableCollectionRef" :RelatedObj="DynamicWikiData"
              :RelatedObjidentifier="CurrentEntity.Primary_Field_Name" :System="System" :SystemEntities="SystemEntities" :RADB="RADB"
      />
        <v-dialog v-model="dialog" max-width="500px" persistent>
         
          <v-card>
            <!-- tab.EntitySource === '{{tab.EntitySource}}'  -->
            <v-img
            width="500"
            src="@/assets/RABaseBG-Soft.png"
            ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
              <v-card-title class="mediumoverline white--text">
                {{ formTitle }}<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">{{TableIcon}}</v-icon>
              </v-card-title>
              <v-card-subtitle class="overline white--text">
              <v-icon  class="actionicon" small :color="formSubTitleIconColor">{{formSubTitleIcon}}</v-icon> {{formSubTitle}}
            </v-card-subtitle></div>
                <v-card-text>
                  
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                       <FieldsForm :FormFields="Headers" :Validating="Validating" :DataTableView="true" @save="save" :DataObject="editedItem" :tab="{}" />
                      </v-col>
                    </v-row>       
                </v-card-text>
                <v-card-actions class="white documenttypeshadowinvert">                  
                <v-btn color="orange" dark class="overline" @click="close()">Cancel</v-btn>
                  <v-spacer></v-spacer>
                <v-btn color="green" dark  class="overline" @click="ValidateForm()">Save</v-btn>
              </v-card-actions>
                 </v-img>
              </v-card>
            </v-dialog>
        <v-dialog v-model="exportdialog" max-width="500">
      <v-card>
        <v-img
          width="500"
          src="@/assets/RABaseBG-Soft.png"
          ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
            <v-card-title class="mediumoverline white--text">
              Export {{TableName}}<v-spacer></v-spacer><v-icon  class="actionicon" x-large color="white">{{TableIcon}}</v-icon>
            </v-card-title>
            <v-card-subtitle class="overline white--text">
            <v-icon  class="actionicon" small color="green">mdi-database-export</v-icon> Export {{TableName}} here
          </v-card-subtitle>
          </div>
        <v-card-text class="FormFields">
          This will export all data in your curent view, in .CSV Format. Choose below whether to Export just for viewing, or to Export for importing of Re-Importing the sheet
          to perform Bulk updates to the {{TableName}} Records:
          <v-list class="transparent">
            <v-list-item>
              <v-btn @click="ExportData()" class="overline blue--text" text>Export for View</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn @click="ExportDataforReimport()" class="overline blue--text" text>Export for Reimport</v-btn>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions  class="white documenttypeshadowinvert">
          <v-btn color="orange" dark class="overline" @click="closeExport()">Cancel</v-btn>
          </v-card-actions>           
        </v-img>
      </v-card>
    </v-dialog>
    <v-toolbar outlined :class="$vuetify.theme.dark? 'my-6 elevation-1 SoftBuilderGradient' : 'my-6 elevation-1 SoftBuilderGradient'" flat 
    v-if=" $route.name !== 'MyAccount' && StatusField && !RelatedTableDashView">
          <v-layout col class="justify-space-between"  v-for="item in StatusField.Options" :key="item.itemObjKey">
            {{item.Name}}
            <v-divider s vertical></v-divider>
            {{GetDataCountbyStatus(item)}}           
          </v-layout>
        </v-toolbar>
      <v-layout row class="justify-center" v-if="$route.name !== 'MyAccount' || $route.name === 'MyAccount' && $route.query && $route.query.TableType" :style="tab.EntitySource === 'SubCollection' ? '' : 'margin-bottom: -40px;'">
          <LookupComponentSubCollections @LinkSubCollectionArray="LinkSubCollectionArray"
         :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" v-if="SCViewExistingLookupDialog"
         @CancelSelection="CancelSCAddParentDialog" @CancelAddParentDialog="CancelSCAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders"
         :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional"
         :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="SCViewExistingLookupDialog"
         :SubCollectionLookupArrays="SubCollectionLookupArrays" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
         
      <v-card class="transparent" flat width="94%">
      <!-- <v-toolbar rounded src="@/assets/RABaseBG.jpeg" elevation="12"style="margin-bottom:-40px;" color="primary white--text"> -->
      
        
     <v-toolbar class="BoldBuilderGradient white--text"  v-if="$route.name !== 'MyAccount' && OptionSetsFilterFields && OptionSetsFilterFields.length > 0 && !RelatedTableDashView || 
       $route.name !== 'MyAccount' && LookupFilterFields && LookupFilterFields.length > 0 && !RelatedTableDashView" outlined dense style="margin-bottom: 30px;" dark>
         <v-layout col class="justify-start">
           <h3 class="my-8">Data Filters</h3><v-spacer></v-spacer><span v-for="textfield in OptionSetsFilterFields" :key="textfield.itemObjKey">
         <div width="100px"><br>
         <!--  v-model="{{textfield.Name.split(' ').join('')}}filter"<span v-if="textfield.HasDeepHierarchy && textfield.Levels && textfield.Levels[1]">
            @change="{{textfield.Levels[1].FieldName}}filter = ''"</span> -->
           <v-autocomplete class="mx-5"
            :items="textfield.Options" 
            v-model="textfield.ActiveFilter"          
            item-text="Name"
            :label="textfield.DisplayName"
            autowidth
            return-object
            chips
            flat
            />
           </div>
           <!-- <span v-if="textfield.HasDeepHierarchy && textfield.Levels && textfield.Levels[1]">
         <div width="100px" v-if="textfield.ActiveFilter">
           <v-autocomplete class="mx-5"
            :items="textfield.ActiveFilter.Options"
            v-model="textfield.ActiveFilterChild"
            item-text="Name"
            :label="textfield.Levels[1].FieldName"
            autowidth
            return-object
            chips
            flat
            />
           </div></span> -->
           </span><span v-for="textfield in LookupFilterFields" :key="textfield.itemObjKey">
         <div width="100px"><br>
           <v-autocomplete class="mx-5"
            :items="textfield.Array"
            v-model="textfield.ActiveFilter" 
            :item-text="textfield.LookupFieldName"
            :label="'by '+textfield.RelatedRecord"
            autowidth
            return-object
            chips
            flat
            />
           </div></span>
           <v-btn outlined fab small class="elevation-6"  style="margin-top:25px;" >
             <v-icon @click="ResetFilters()">mdi-close</v-icon>
           </v-btn>
           </v-layout>
         </v-toolbar>
         
        <v-toolbar rounded outlined
        :style="{ zIndex:1 }"
          dark
        >
          <v-toolbar-title  class="mediumoverline"> <v-icon  class="mx-1" x-large color="white">{{TableIcon}}</v-icon>{{TableName}}</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
       
          <v-icon @click="ToggleIsCalendarView()" v-if="HasCalendarView && IsCalendarView" class="soloactionicon mx-1" color="white">mdi-view-sequential</v-icon>
          
          <v-icon @click="ToggleIsCalendarView()" v-if="HasCalendarView && !IsCalendarView" class="soloactionicon mx-1" color="white">mdi-calendar</v-icon>
          <v-spacer></v-spacer>
          <v-text-field dark
            v-model="search"
            
            label="Search"
            single-line
            hide-details
            class="mediumoverline"
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" dark>mdi-close</v-icon>
          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ on }">
            <v-btn outlined fab small class="elevation-6" dark >
              <v-icon  v-on="on" v-if="CanCreate || userIsAdmin || HasCharts || HasProcesses" >mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense class="SoftBuilderGradient">
              <v-list-item dense @click="ActivateRelevantdialog()"  v-if="CanCreate || userIsAdmin">
                  <v-list-item-title class="subtleoverline">
                    New
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="pop">mdi-plus-thick</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item dense @click="importdialog = !importdialog" v-if="ImportActive">
                  <v-list-item-title class="subtleoverline">
                    Import
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="links">mdi-database-import</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item dense @click="ActivateExportDialog(Data,Headers)"  v-if="ExportActive">
                  <v-list-item-title class="subtleoverline">
                    Export
                  </v-list-item-title>
                <v-list-item-action>
                    <v-icon class="actionicon" color="blue lighten-2">mdi-database-export</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item dense v-if="ChartsActive" @click="ActivateChartView()">
                  <v-list-item-title class="subtleoverline">
                    Charts
                  </v-list-item-title>
                <v-list-item-action>                  
                    <v-icon class="actionicon" color="success">mdi-chart-bar</v-icon>
                  </v-list-item-action>
                </v-list-item>
                 <v-list-item dense v-if="TableEditActive" @click="EditingTable = !EditingTable">
                  <v-list-item-title class="subtleoverline">
                    {{EditingTable? 'Cancel Table Edit' : 'Edit Table'}}
                  </v-list-item-title>
                <v-list-item-action>                  
                    <v-icon class="actionicon" color="white">mdi-cogs</v-icon>
                  </v-list-item-action>
                </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-card>
    </v-layout>
      <CalendarViewComp v-if="IsCalendarView && CurrentEntity.Entity_Type.Name !== 'Booking Schedule'"
      :CurrentEntity="CurrentEntity" :Data="Data"
      />
      <ScheduledBookingViewComp v-if="IsCalendarView && CurrentEntity.Entity_Type.Name === 'Booking Schedule'"
      :CurrentEntity="CurrentEntity" :Data="Data" @ActivateProcessing="ActivateProcessing"
      />
      <!--   hide-default-footer -->
       <!-- <v-data-table
      v-if="!Refreshing && STDTableView"
      :dark="AppisDarkMode"
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="Headers"
      :items="Data"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1"
  > -->
      <v-data-table
      v-if="!Refreshing && STDTableView"
      :dark="AppisDarkMode"
      :page.sync="page"
      @page-count="pageCount = $event"
      :headers="ComputedHeaders"
      :items="DataFiltered"      
      :items-per-page="itemsPerPage"
      :hide-default-header="EditingTable"
     
     :class="$vuetify.theme.dark? 'elevation-1 dbcard dbtable darken-2' : 'elevation-1 dbtable dbcard'"
      >
      <template v-slot:top>
        <div style="padding-top:40px;"/>
      </template>
      <template v-slot:header="{ props }">
            <!-- border-right: 2px solid green; -->
            
            <thead class="v-data-table-header" v-if="EditingTable">
                <tr>
                 
                <th :style="EditingTable? 'text-align:left;padding:10px;border-right: 2px solid red;' : 'text-align:left;padding:10px;'"
                :draggable="EditingTable"
                @dragstart="Headerdragstart(head,headerindex, $event)"  @dragover.prevent @drop.prevent="Headerdragfinish(head,headerindex, $event)"
                class="overline" v-for="(head,headerindex) in props.headers" :key="head.itemObjKey">{{ head.text.toUpperCase() }}
                </th>
                </tr>
            </thead>
      </template>
     <template v-slot:[`item.Primary_Field_Value`]="{ item }">
        <v-btn outlined fab small @click="ViewItem(item)" class="elevation-6 accent--text"><v-icon small v-if="item.CanView">{{TableIcon}}</v-icon>
            <v-badge color="error" v-if="item.notifications" >
            <span slot="badge"> {{item.notifications}}</span>
            </v-badge>
            </v-btn>
            {{item.Primary_Field_Value}}
      </template>
      <template v-slot:[`item.Owner.Full_Name`]="{ item }">
        <v-avatar size="35">
        <v-img :src="item.Owner.Profile_Photo" v-if="item.Owner && item.Owner.Profile_Photo"/>
        <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Owner || !item.Owner.Profile_Photo"/>
        </v-avatar>
        <span v-if="item.Owner && !MobileView">{{' '+item.Owner.Full_Name}}</span>
      </template>
        
      <!-- <template v-if="HeadershaveOwner" v-slot:[`item.Owner`]="{ item }">
      <v-avatar size="35">
        <v-img :src="item.Owner.Profile_Photo" v-if="item.Owner && item.Owner.Profile_Photo"/>
        <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Owner || !item.Owner.Profile_Photo"/>
        </v-avatar>
        <span v-if="item.Owner">{{' '+item.Owner.Full_Name}}</span>
       
    </template> -->
    <!-- <template v-slot:[`item.Name`]="{ item }">        
        <v-btn outlined fab small @click="ViewItem(item)" class="elevation-6 accent--text">        
          <v-icon small v-if="item.CanView">{{TableIcon}}</v-icon></v-btn>
          {{item[PrimaryField]}}
      </template> -->
      <template v-slot:[`item.actions`]="{ item }">
        <!-- PrimaryField {{PrimaryField}} -->
      <v-btn outlined fab x-small @click="editItem(item)" class="elevation-6 orange--text"
       v-if="item && item.CanEdit">
       
          <v-icon small>
            mdi-pencil
            </v-icon>
        </v-btn>
        <v-btn outlined fab x-small @click="deleteItem(item)" class="elevation-6 red--text"
        v-if="item && item.CanDelete">
          <v-icon small>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
        
      </template>
      </v-data-table>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldsForm from '@/components/Database/Fields/FieldsForm';	
import LookupComponentSubCollections from '@/components/Database/LookupComponentSubCollection'
import CalendarViewComp from '@/components/SuitePlugins/CalendarTables/CalendarViewComp'
import ScheduledBookingViewComp from '@/components/SuitePlugins/CalendarTables/ScheduledBookingViewComp'
import FieldConfig from '@/components/Database/Fields/FieldConfig';	
import FieldViewerComponent from '@/components/Database/Fields/ReadOnly/FieldViewerComponent';
import LookupSubColDialogComponent from '@/components/Database/LookupSubColDialogComponent'
export default {
    props: ['Data','Headers','TableName','TableIcon','DynamicWikiData','RADB','SystemEntities','RelatedTableDashView',
    'TableCollectionRef','CanCreate','PrimaryField','id','tab','RelatedObj','MasterTableObj','EntityCollectionRef','TableEntryPath','CurrentEntity'],
    components: {
        FieldsForm,
        LookupComponentSubCollections,
        CalendarViewComp,
        ScheduledBookingViewComp,
        FieldConfig,
        FieldViewerComponent,
        LookupSubColDialogComponent
    },
    data() {
        return {
          SubColLookupDialogActive: false,
          InboundStockRecords: [],
          OutboundStockRecords: [],
          WarehouseEntries: [],
          ConsumeBulkQuantityArrayCounter: 0,
          FilterHeaders: [],
          OptionSetsFilterFields: [],
          LookupFilterFields: [],
          Validating: false,
          search: '',
          HeadersTab: 0,
          page: 1,
          pageCount: 0,
          itemsPerPage: 10,
          IsVCardView: false,
          IsCalendarView: false,
          STDTableView: false,
          subcollectstep: 1,
          SelectedSubcollectionArray: [],
          SubColLookupDialog: false,
          SubCollectionItemDialog: false, 
          SCViewExistingLookupDialog: false,
          SCAddNewParentLookupDialog: false,
          AddNewParentLookupDialog: false,
          ViewExistingLookupDialog: false,
          FieldFilter: '',
          ParentTitle: '',
          ParentCollection:  '',
          NewParentPrimary: '',
          ParentLookup: '',
          NewParentAdditional: [],
          SubCollectionLookupArrays: [],
          LookupArray: [],
          LookupArrayHeaders: [],
            DraggingHeaderIndex: -1,

            EditingTable: false,
            HeaderTypes: [
            'Single Line Text',
            'Multiple Lines Text',
            'Option Set',
            'Lookup',
            'Number Field',
            'Date'  
            ],
            DefaultNewField: {Name: '',Type: '',DisplayName: ''},
            NewField: {Name: '',Type: '',DisplayName: ''},
            Refreshing: false,
          editedIndex: -1,
          defaultItem: {
            id: '',
          },
          editedItem: {
          id: '',
          },
          rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
          dialog: false,
          importdialog: false,
          importsnackbar: false,
          ImportHeaders: [],
          ImportingProgressDialog: false,
          Importcount: '',
          importresultsdialog: false,
          NewImportsArray: [],
          PartialFailuresArray: [],
          SuccessfulImportsArray: [],
          CSVResult: [],
          Import: {Name: '',AllImports: [],PartialFailures: [], Dataset: '', CreatedOn: '', CreatedBy: {id: '', Name: ''}},
          ImportFile: '',
          exportdialog: false,
          ExportDataArray: [],
          ExportHeaders: [],
          FieldSearch: '',
          CanCreate: false,

        }
    },
    computed:{
       DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
      StoreProductEntity(){
            return this.CurrentEntity.Entity_Type.Name === 'Store Product'
        },
       SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
      StatusField(){
            if(this.FinalTableEntity){
                return this.FinalTableEntity.AllFields.find(obj => obj.id === 'Status')
            }
        },
      LookupHeaders(){
        return this.Headers
        .filter(header => {
          return header.Type === 'Lookup'
        })
      },
      OptionSetHeaders(){
        return this.Headers
        .filter(header => {
          return header.Type === 'Option Set' && header.id !== 'Status'
        })
      },
      NumberFieldHeaders(){
        return this.Headers
        .filter(header => {
          return header.Type === 'Number Field'
        })
      },
      DateHeaders(){
        return this.Headers
        .filter(header => {
          return header.Type === 'Date'
        })
      },
      
      SelectedFilterHeaders(){
        return this.FilterHeaders.filter(field => {
          return field.ActiveFilter
        })
      },
      System(){
        return this.$store.state.SystemObj
      },
      SystemConfigorRA(){
        return this.$route.meta && this.$route.meta.SystemBuilder || this.$route.meta && this.$route.meta.RecordisSystem
      },
      FinalTableEntity(){
        return this.IsRelatedTable ? this.SystemEntities.find(obj => obj.id === this.tab.Entityid) : this.CurrentEntity
      },
       IsRelatedTable(){
            return this.tab.Entityid !== this.CurrentEntity.id && this.$route.params.id && this.tab.RelationshipField
            || this.RelatedTableDashView
            || this.tab.Entityid !== this.CurrentEntity.id && this.$route.meta && this.$route.meta.RouteEventID === 1027
            || this.tab.Entityid !== this.CurrentEntity.id && this.$route.meta && this.$route.meta.RouteEventID === 1028
        },
      DataMapped(){
        return this.Data
      },
      DataFiltered(){
        return this.DataSearched
        .map(item => {
          item.FilterMatch = true
          //console.log(this.DataSearched)
          //console.log('this.OptionSetsFilterFields',this.OptionSetsFilterFields)
          if(this.OptionSetsFilterFields && this.OptionSetsFilterFields.length > 0){
            this.OptionSetsFilterFields.map(header => {
              //console.log('header',header)
              if(header.ActiveFilter && header.ActiveFilter.ID){
                if(!item[header.id] || item[header.id] && item[header.id].ID !== header.ActiveFilter.ID){
                  item.FilterMatch = false
                }
              }
              else if(header.Type === 'Lookup' && header.ActiveFilter && header.ActiveFilter.id){
                if(!item[header.id] || item[header.id] && item[header.id].id !== header.ActiveFilter.id){
                  item.FilterMatch = false
                }
              }
            })
          }
          if(this.LookupFilterFields && this.LookupFilterFields.length > 0){
            this.LookupFilterFields.map(header => {
              if(header.ActiveFilter && header.ActiveFilter.id){
                if(!item[header.id] || item[header.id] && item[header.id].id !== header.ActiveFilter.id){
                  item.FilterMatch = false
                }
              }
            })
          }
         return item
        })
        .filter(item => {
          return item.FilterMatch
        })
      },
      DataSearched(){
        return this.DataMapped.filter(item => {
          if(this.search && this.CurrentEntity && this.CurrentEntity.Primary_Field_Name){
            return this.ItemPassSearch(item)
          }
          else{
            return item
          }
        })
        
      },
      
      ComputedHeaders(){
        let headers = JSON.parse(JSON.stringify(this.Headers))
        let actionheader = {
            text: 'Actions',
            value: 'actions',
            class: 'overline'
        }
        let oncheck = headers.find(obj => obj.value === 'actions')
        if(!oncheck){
          headers.push(actionheader)
        }
        return headers
      },
      PotentialHeaderFields(){
        return this.CurrentEntity.AllFields.map(field => {
          return field
        })
        .filter(field => {
          if(this.FieldSearch){
            return field.DisplayName.toLowerCase().includes(this.FieldSearch.toLowerCase())
          }
          else{
            return field
          }          
        })
      },
      RASystemOpsDB(){
      if(this.NeedsRASync){
       return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities')
      }
    },
      RAOperationalDB(){
      if(this.NeedsRASync){
       return this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id)
      }
    },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
      NeedsRASync(){
        return this.$store.state.NeedsRASync && this.$route.meta && this.$route.meta.TableBuilder || 
        this.$store.state.NeedsRASync && this.SystemConfigorRA && this.tab.Entityid === 'entities'       
        },
      ImportActive(){
        if(this.tab.EntitySource === 'System Routes'){
          return  this.userLoggedIn && this.userLoggedIn.CanImport || this.userIsAdmin
        }
      },
      ExportActive(){
        if(this.tab.EntitySource === 'System Routes'){
          return this.userLoggedIn && this.userLoggedIn.CanExport || this.userIsAdmin
        }        
      },
      ChartsActive(){

      },
      TableEditActive(){
        if(this.tab.EntitySource === 'System Routes' && this.tab.Entityid === this.CurrentEntity.id){
          return this.userIsAdmin
        }
      },
      HasCalendarView(){
        if(this.CurrentEntity){
          return this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Calendar' || this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Booking Schedule'
        }
      },
      HasVCardView(){
        return this.CurrentEntity && this.CurrentEntity.HasVCardView
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New '+this.TableName : 'Edit '+this.TableName
      },
      formSubTitle(){
        return this.editedIndex === -1 ? 'Fill the form below and add a new '+this.CurrentEntity.SingleName : 'Edit the '+this.CurrentEntity.SingleName+' '+this.editedItem[this.CurrentEntity.Primary_Field_Name]
      },
      formSubTitleIcon(){
        return this.editedIndex === -1 ? 'mdi-plus-thick' : 'mdi-pencil'
      },
      formSubTitleIconColor(){
        return this.editedIndex === -1 ? 'green' : 'orange'
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userBUID () {
        return this.$store.getters.userBUID
      },
      userBUChildren () {
        return this.$store.getters.userBUChildren
      },
      userBUParents () {
        return this.$store.getters.userBUParents
      },
      UsersStore(){
        return this.UsersArray
      },
      BusinessUnitsStore(){
          return this.$store.state.BusinessUnitsArray
        },
      UsersStore(){
          return this.$store.state.UsersArray
      },
      HeadershaveOwner(){
          let ownerfield = this.Headers.find(obj => obj.value === 'Owner')
          return ownerfield
      },

      userLoggedIn () {
        return this.$store.getters.user
      },
      HeaderNames() {
      let headers = JSON.parse(JSON.stringify(this.Headers))      
      headers = headers.map(header => {
        return header.propvalue
      }).filter(header => {
        return header !== 'id'
      })
      this.Headers.map((header,i) => {
        if(header.HasTimeInput){
          let newindex = i-1+2
          headers.splice(newindex, 0, header.value+'_Time')
        }
      })
      return headers
    },
      HeaderNamesSplit() {
        return this.HeaderNames.map(header => {
        let correctheader = header
          return correctheader
        })
      },
    },
    watch: {
      HasCalendarView(v){
        if(v){
          this.IsCalendarView = true
        }
      },
      CanCreate(value) {
            if (value) {
             this.$store.commit('SetCurrentItemModerator',value)          
            }
        },
      Headers: {
        handler: function(newvalue, oldvalue) {   
              //console.log('this.InvitedGroups mustreadnotices',this.mustreadnotices)
            if(newvalue !== oldvalue){
              this.SetFilterHeaders()              
            }    
            },
            deep: true
      },
      FilterHeaders: {
        handler: function(newvalue, oldvalue) {   
              ////console.log('this.InvitedGroups mustreadnotices',this.mustreadnotices)
            if(newvalue !== oldvalue){
              //console.log('FilterHeaders',newvalue, oldvalue)
              this.OptionSetsFilterFields = this.FilterHeaders.filter(field => {
                  return field.Type === 'Option Set'
                }) 
              this.LookupFilterFields = this.FilterHeaders.filter(field => {
                  return field.Type === 'Lookup'
                }) 
            }    
            },
            deep: true
      },
      SocialItemInteractMethod(v){   
        let acceptedmethods = ['ActivateRelevantdialog']   
        //console.log(this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v) && this.$route.meta && this.$route.meta.TableBuilder || 
        v && acceptedmethods.includes(v) && this.tab.Entityid === this.SocialItemInteractMethodProp){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      if(this.HasCalendarView && this.$route.name !== 'MyAccount'){
       this.ToggleIsCalendarView()
      }
      console.log('this.RelatedTableDashView',this.RelatedTableDashView)
     //console.log('this.$store.state',this.$store.state,this.$route.meta)
      //console.log('this.tab',this.tab)
      //console.log('this.Headers',this.Headers,this.TableCollectionRef,this.tab)
      this.SetFilterHeaders()
      //this.GetDefaultStatusField()
      let queryobj = this.$route.query
      if(this.$route.name === 'MyAccount' && !queryobj || this.$route.name === 'MyAccount' && queryobj && Object.keys(queryobj).length === 0 && queryobj.constructor === Object){
        this.ToggleIsCalendarView()
      }
      else{
        this.STDTableView = true
      }
      //console.log('this.HasCalendarView',this.HasCalendarView,',this.CurrentEntity',this.CurrentEntity,'this.IsCalendarView',this.IsCalendarView)
    },
    methods:{
      LookupObjRoutetoQuery(LookupObj){
        let newroute = { path: LookupObj.RoutePath, query: LookupObj.RouteQuery}
        this.$router.push(newroute)
      },
      DeactivateSubColLookupDialog(){
        this.SubColLookupDialog = false
      },
      PerformCalulation(Record,field,IsRollup){
            //console.log('PerformCalulation',Record,field)
            let result = 0
                let formulas = JSON.parse(JSON.stringify(field.FieldFormulas))
                formulas = formulas.map(formula => {                
                    if(formula.input === 'percentage field' && formula.fieldName || formula.input === 'field' && formula.fieldName){
                        if(Record[formula.fieldName.Name]){
                            formula.integer = Record[formula.fieldName.Name]
                            if(formula.input === 'percentage field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                                formula.integer = formula.integer*(formula.integer/100)
                            }
                            else if(formula.input === 'field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                            } 
                        }
                    }
                    return formula
                })
                formulas.map((formula,i) => {
                    if(i === 0){
                        result = formula.integer
                    }
                    else if(formulas[i-1].operation === '-'){
                        result = result-formula.integer
                    }
                    else if(formulas[i-1].operation === '+'){
                        result = result+formula.integer
                    }
                    else if(formulas[i-1].operation === '*'){
                        result = result*formula.integer
                    }
                    else if(formulas[i-1].operation === '/'){
                        result = result/formula.integer
                    }
                    else if(formulas[i-1].operation === '='){
                        result = result
                    }
                })
                // if(IsRollup)
            this.$emit('UpdateRecordCalculation',this.Record,field,result)
            return result
        },
      ItemPassSearch(item){
        let pass = false
        let searchfields = this.CurrentEntity.AllFields.filter(field => {
          return field.IsSearch
        })
        //console.log('searchfields',searchfields)
        if(item[this.CurrentEntity.Primary_Field_Name] && item[this.CurrentEntity.Primary_Field_Name].toLowerCase().includes(this.search.toLowerCase())){
          pass = true
        }
        else{
          searchfields.map(field => {
              if(field.Type === 'Single Line Text' || field.Type === 'Multiple Lines Text'){
                if(item[field.Name] && item[field.Name].toLowerCase().includes(this.search.toLowerCase())){
                  pass = true
                }
              }
              else if(field.Type === 'Option Set'){
                if(item[field.Name] && item[field.Name].Name.toLowerCase().includes(this.search.toLowerCase())){
                  pass = true
                }
              }
              else if(field.Type === 'Lookup'){
                if(item[field.Name] && item[field.Name][field.LookupFieldName].toLowerCase().includes(this.search.toLowerCase())){
                  pass = true
                }
              }
          })
        }
        return pass
        
      },
      SetFilterHeaders(){
        //console.log('Headers for FilterHeaders',this.Headers)
              this.FilterHeaders = this.Headers.filter(field => {
                return field.IsFilter
              }).map(field => {
                let fieldobj = Object.assign({},field)
                return fieldobj
              })
      },
      GetDataCountbyStatus(item){
        return this.DataFiltered.filter(record => {
          return record.Status && record.Status.ID === item.ID
        }).length
      },
      ValidateForm(){
        this.Validating = false
        setTimeout(() => {
          this.Validating = true
        }, 50);
      },
      ResetFilters() {
        this.FilterHeaders.map(field => {
          field.ActiveFilter = ''
        })
       
      },
      ViewMapItem(FieldBreakdown,item) {
        let combinedlink = ''
        FieldBreakdown.map((breakdown,i) => {
            combinedlink = combinedlink+item[breakdown.Name]
            if(i-1+2 < FieldBreakdown.length){
                combinedlink = combinedlink+'+'
            }
        })
        let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
        
        window.open(FinalLink, '_blank')
      },
      HeaderPhysicalAddress(item,header){
        //console.log('HeaderPhysicalAddress(item,header)',item,header)
        let boolean = true
        if(header.Type === 'Common Field' && header.CommonFieldType === 'Physical Address'){
          header.FieldBreakdown.map(breakdownfield => {
          if(!item[breakdownfield.Name]){
            boolean = false
          }
        })
        }
        else{
          boolean = false
        }
        return boolean
      },
      UpdateRelated(field,RelatedBuildID){
            let entity = this.SystemEntities.find(obj => obj.id === RelatedBuildID)
            if(entity){
                field.RelatedLocation = entity.SingleName
                field.RelatedRecord = entity.DisplayName
                field.LookupFieldName = entity.RecordPrimaryField.Name
            }
        },
      UpdateField(field,prop,value){
            field[prop] = value
            if(!this.AddingNewField){
                
            }
            //console.log(field)
        },
      RefreshComponent(){
        this.EditingTable = false
        this.$emit('RefreshComponent',true)
      },      
      SaveHeaderUpdates(){    
        let length = this.CurrentEntity.AllFields.length
        this.CurrentEntity.AllFields.map((header,index) => {
          let onheaderlist = this.ComputedHeaders.find(obj => obj.Name === header.Name)
          //console.log(onheaderlist)
          if(onheaderlist){
            
            header.text = onheaderlist.text
            header.value = onheaderlist.value
            header.IsHeader = onheaderlist.IsHeader
            header.HeaderIndex = onheaderlist.HeaderIndex
          }
          if(header.value !== 'action' && header.value !== 'actions' && header.value !== 'LookupObj'){
            if(typeof header.IsHeader === 'undefined'){
              header.IsHeader = false
            }
            if(header.IsHeader === false){
              header.HeaderIndex = ''
            }
            if(typeof header.HeaderIndex === 'undefined'){
              header.HeaderIndex = ''
            }
            if(typeof header.value === 'undefined'){
              header.value = ''
            }
            if(typeof header.text === 'undefined'){
              header.text = ''
            }
            if(this.NeedsRASync){                    
              this.RAOperationalDB.collection('AllFields').doc(header.Name).update({
                IsHeader: header.IsHeader,
                value: header.value,
                text: header.text,
                HeaderIndex: header.HeaderIndex,
                }).then(radoc => {
                this.EntityCollectionRef.collection('AllFields').doc(header.Name).update({
                IsHeader: header.IsHeader,
                value: header.value,
                text: header.text,
                HeaderIndex: header.HeaderIndex,
                }).then(doc => {
                  if(index-1 === length){
                    this.RefreshComponent()
                  }                    
                }) 
              })
            }
            else{
              this.EntityCollectionRef.collection('AllFields').doc(header.Name).update({
                IsHeader: header.IsHeader,
                value: header.value,
                text: header.text,
                HeaderIndex: header.HeaderIndex,
                }).then(doc => {
                  if(index-1 === length){
                    this.RefreshComponent()
                  }                    
                }) 
            }            
          }
        })
      },
      UpdateHeaders(item){
        if(item.IsHeader){
          let index = this.Headers.length-2
          let newfield = Object.assign({},item)
          newfield.text = newfield.DisplayName
          newfield.class = ' overline'
          newfield.HeaderIndex = index
          newfield.value = newfield.Name
          newfield.propvalue = newfield.value
          if(newfield.Type === 'Option Set'){
            newfield.value = newfield.value+'.Name'
          }
            newfield.propvalue = newfield.value
            this.Headers.push(newfield)
        }
        else{
          if(!item.Primary){
           let indexobj = this.Headers.find(obj => obj.Name === item.Name)
           let index = this.Headers.indexOf(indexobj)
            this.Headers.splice(index,1)
            this.Headers.map((header,headerindex) => {
              header.HeaderIndex = headerindex
            }) 
          }
          
        }
      },
      
      DownloadImportTemplate(){
      let copyText = this.HeaderNamesSplit

      var csvfilename = this.CurrentEntity.DisplayName+' Import Template.csv'
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(copyText))
      element.setAttribute('download', csvfilename)
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      // }
    },
    CloseImportresultsdialog(){
      this.importresultsdialog = false
    },
    onImportfileSelected(event){
      this.ImportFile = event.target.files[0]
    },
      closeImport(){
      this.importdialog = false
      this.ImportFile = null
      this.Import.Name = ''
    },

    saveImport() {
      this.ImportingProgressDialog = true
      setTimeout(() => {
      const totalprogresselement = document.getElementById('importprogressbar')
      totalprogresselement.setAttribute('max','100')
      totalprogresselement.setAttribute('value',1)
    }, 200);
      this.Importcount = 'Preparing Import'
      let importLocalURL = URL.createObjectURL(this.ImportFile)
      var reader = new FileReader();
      let test = reader.readAsText(this.ImportFile);
      let vm = this
      reader.addEventListener('load', function(e) {
            var text = e.target.result;
            vm.CSVConvertToJSON(text)
      });
    },


    CheckDelimiter(lines){
      let headertest = lines[0].split("|");
      if(headertest.length ===1){
        this.ConversionCSVtoJJSON(lines,'|')
      }
      else{
        this.ConversionCSVtoJJSON(lines, '|')
      }
      //console.log('headertest')
      //console.log(headertest)
    },
    ConversionCSVtoJJSON(lines,delimiter){
      let dataonly = Object.assign([],lines)
      dataonly.splice(0,1)
      //console.log(dataonly)
      this.CSVResult = []
      this.ImportHeaders=lines[0].split(delimiter);
      //console.log('this.ImportHeaders')
      //console.log(this.ImportHeaders)
      let finalindex = this.ImportHeaders.length-1
    let finalheader = this.ImportHeaders[finalindex]
    if(finalheader.includes('id')){
      this.Reimport = true
      confirm('We picked up this is a Reimport. Shall you continue?') && this.ProceedReimport(lines,delimiter)
    }
    else{
      for(var i=1;i-1+2<lines.length;i++){

      var obj = {};
      var currentline=lines[i].split(delimiter);
      
      for(var j=0;j<this.ImportHeaders.length;j++){
        obj[this.ImportHeaders[j]] = currentline[j];
      }
      let resultcheck = this.CSVResult.find(res => res.Name == obj.Name)
      this.CSVResult.push(obj);
      //console.log("this.CSVResult length is "+this.CSVResult.length)


    }
    this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
      this.CSVResult.map((res,i) => {
        
        this.UpdateFiresStore(res,i)
        
      })
    }
    },
    ProceedReimport(lines,delimiter){
    for(var i=1;i-1+2<lines.length;i++){
        var obj = {};
        var currentline=lines[i].split(delimiter);
        
        for(var j=0;j<this.ImportHeaders.length;j++){
          obj[this.ImportHeaders[j]] = currentline[j];
        }
        let resultcheck = this.CSVResult.find(res => res.Name == obj.Name)
        this.CSVResult.push(obj);
        //console.log("this.CSVResult length is "+this.CSVResult.length)
      }
      this.Importcount = 'Preparing '+this.CSVResult.length+' records...'
        this.CSVResult.map((res,i) => {
            this.UpdateFiresStoreReimport(res,i)
        })
  },
  // Headers
  RoundUpReImport(result,ResultLength,Index){
      let ImportSession = Object.assign({},result.ImportSession)
      let SingleResult = Object.assign({},result.SingleResult)
      let OriginalImportObject = Object.assign({},result.OriginalImportObject)
      let FailureObject = Object.assign({},result.FailureObject)
      //console.log(ImportSession,SingleResult,OriginalImportObject,FailureObject)
        if(ImportSession.hasFailures){
        this.PartialFailuresArray.push(FailureObject)

        }
        else{
          this.SuccessfulImportsArray.push(OriginalImportObject)
        }
        this.NewImportsArray.push(OriginalImportObject)
        //console.log('ImportSession',ImportSession)
        //still t check mandataory fields, do not add if not present
        this.TableCollectionRef.doc(OriginalImportObject.id).set(ImportSession).then(doc => {
          let AllImportObj = this.NewImportsArray.find(imp => imp.id == OriginalImportObject.id)
          if(typeof AllImportObj !== 'undefined'){
          let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
          this.NewImportsArray[AllImportObjIndex].id = OriginalImportObject.id
          //console.log(this.NewImportsArray[AllImportObjIndex])
          //console.log(this.NewImportsArray)
          }
          let PartialImportObj = this.PartialFailuresArray.find(imp => imp.id == OriginalImportObject.id)

          if(typeof PartialImportObj !== 'undefined'){
          let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
          this.PartialFailuresArray[PartialImportObjIndex].id = OriginalImportObject.id
          //console.log(this.PartialFailuresArray[PartialImportObjIndex])
          //console.log(this.PartialFailuresArray)
          }
          let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp.id == OriginalImportObject.id)
          if(typeof SuccessImportObj !== 'undefined'){
          let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
          this.SuccessfulImportsArray[SuccessmportObjIndex].id = OriginalImportObject.id
          //console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
          //console.log(this.SuccessfulImportsArray)
          }
          let indexstring = Index.toString()
          let totallength = ResultLength.toString()
          let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
          this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
          const totalprogresselement = document.getElementById('importprogressbar')
          totalprogresselement.setAttribute('value',runningPercentage)
          if(Index-1+2 === ResultLength){
          //console.log(this.NewImportsArray,this.PartialFailuresArray,this.SuccessfulImportsArray) 
          let Created_On = new Date()
          let User = this.userLoggedIn
          let RefinedHeaders = this.ImportHeaders.map(header => {
            let obj = {text: header, value: header}
            return obj
          })
          //console.log("this.NewImportsArray at final length")
          //console.log(this.NewImportsArray)
          let DataImport = {name: this.Import.Name, dataset: this.CurrentEntity.DisplayName, datasetrecordpath: this.CurrentEntity.SingleName.split(' ').join(''), createdon: Created_On, createdby: {id: User.id, Full_Name: User.Full_Name}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
          db.collection('dataimports').add(DataImport).then(doc => {
          var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
          var uploadTask = storageRef.put(this.ImportFile);
          uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
              db.collection('dataimports').doc(doc.id).update({
                      csvfile: url
              })
              this.ImportFile = ''
                  }),
                  this.importresultsdialog = true
                  this.importdialog = false
                  this.Import.Name = ''
                  this.ImportingProgressDialog = false
          })
          }
        })
        
        
       
    },
    RoundUpImport(result,ResultLength,Index){
      let ImportSession = Object.assign({},result.ImportSession)
      let SingleResult = Object.assign({},result.SingleResult)
      let OriginalImportObject = Object.assign({},result.OriginalImportObject)
      let FailureObject = Object.assign({},result.FailureObject)
      //console.log(ImportSession,SingleResult,OriginalImportObject,FailureObject)
        if(ImportSession.hasFailures){
        this.PartialFailuresArray.push(FailureObject)

        }
        else{
          this.SuccessfulImportsArray.push(OriginalImportObject)
        }
        this.NewImportsArray.push(OriginalImportObject)
        //console.log('ImportSession',ImportSession)
        //still t check mandataory fields, do not add if not present
        this.TableCollectionRef.add(ImportSession).then(doc => {
          let AllImportObj = this.NewImportsArray.find(imp => imp[this.CurrentEntity.Primary_Field_Name] == ImportSession[this.CurrentEntity.Primary_Field_Name])
          if(typeof AllImportObj !== 'undefined'){
          let AllImportObjIndex = this.NewImportsArray.indexOf(AllImportObj)
          this.NewImportsArray[AllImportObjIndex].id = doc.id
          //console.log(this.NewImportsArray[AllImportObjIndex])
          //console.log(this.NewImportsArray)
          }
          let PartialImportObj = this.PartialFailuresArray.find(imp => imp[this.CurrentEntity.Primary_Field_Name] == ImportSession[this.CurrentEntity.Primary_Field_Name])

          if(typeof PartialImportObj !== 'undefined'){
          let PartialImportObjIndex = this.PartialFailuresArray.indexOf(PartialImportObj)
          this.PartialFailuresArray[PartialImportObjIndex].id = doc.id
          //console.log(this.PartialFailuresArray[PartialImportObjIndex])
          //console.log(this.PartialFailuresArray)
          }
          let SuccessImportObj = this.SuccessfulImportsArray.find(imp => imp[this.CurrentEntity.Primary_Field_Name] == ImportSession[this.CurrentEntity.Primary_Field_Name])
          if(typeof SuccessImportObj !== 'undefined'){
          let SuccessmportObjIndex = this.SuccessfulImportsArray.indexOf(SuccessImportObj)
          this.SuccessfulImportsArray[SuccessmportObjIndex].id = doc.id
          //console.log(this.SuccessfulImportsArray[SuccessmportObjIndex])
          //console.log(this.SuccessfulImportsArray)
          }
          let indexstring = Index.toString()
          let totallength = ResultLength.toString()
          let runningPercentage = Number(((Index/ResultLength)*100).toFixed(2))
          this.Importcount = 'Importing '+runningPercentage+'% complete ('+indexstring+' of '+totallength+')'
          const totalprogresselement = document.getElementById('importprogressbar')
          totalprogresselement.setAttribute('value',runningPercentage)
          if(Index-1+2 === ResultLength){
          //console.log(this.NewImportsArray,this.PartialFailuresArray,this.SuccessfulImportsArray) 
          let Created_On = new Date()
          let User = this.userLoggedIn
          let RefinedHeaders = this.ImportHeaders.map(header => {
            let obj = {text: header, value: header}
            return obj
          })
          //console.log("this.NewImportsArray at final length")
          //console.log(this.NewImportsArray)
          let DataImport = {name: this.Import.Name, dataset: this.CurrentEntity.DisplayName, datasetrecordpath: this.CurrentEntity.SingleName.split(' ').join(''), createdon: Created_On, createdby: {id: User.id, Full_Name: User.Full_Name}, importheaders: RefinedHeaders, allimports: this.NewImportsArray, partialfailures: this.PartialFailuresArray, successfulimports: this.SuccessfulImportsArray, dataimportstatus: 'Imported'}
          db.collection('dataimports').add(DataImport).then(doc => {
          var storageRef = firebase.storage().ref('DataImport/'+Created_On+'/'+this.ImportFile.name);
          var uploadTask = storageRef.put(this.ImportFile);
          uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
              db.collection('dataimports').doc(doc.id).update({
                      csvfile: url
              })
              this.ImportFile = ''
                  }),
                  this.importresultsdialog = true
                  this.importdialog = false
                  this.Import.Name = ''
                  this.ImportingProgressDialog = false
          })
          }
        })
        
        
       
    },
    PrepImportHeadersDefault(ImportSession,SingleResult,OriginalImportObject,FailureObject,ReImport){
      //console.log('PrepImportHeadersDefault',ImportSession,SingleResult,OriginalImportObject,FailureObject)
      if(ReImport){
        ImportSession.id = SingleResult.id
        FailureObject.id = SingleResult.id
      }
      let vm = this
      return new Promise(function(resolve, reject) {
      let length = vm.Headers.length
       if(length === 0){
          resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
        }
        else{
          vm.Headers.map((header,index) => {
            if(typeof SingleResult[header.id] !== 'undefined'){
              let value = SingleResult[header.id].trim()
              //console.log('SingleResult',header,value,vm.CurrentEntity)
              if(header.Type === 'Date' && !header.HasTimeInput && SingleResult[header.id].replace(/\s/g, '').length){
                ImportSession[header.id] = vm.DateFormatter(value)
              }
              else if(header.Type === 'Date' && header.HasTimeInput && SingleResult[header.id].replace(/\s/g, '').length){
                ImportSession[header.id] = vm.DateFormatterwithTimeImport(value+'T'+SingleResult[prop+'_Time'])
              }
              else if(header.Type === 'Number Field'){
                let CommaTODot = value.split(',').join('.')
                ImportSession[header.id] = Number(CommaTODot)
              }
              else{
                ImportSession[header.id] = value
              } 
            }
            if(index-1+2 === length){
              resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
            }
          })
        }      
      })
    }, 
    PrepImportHeadersOptionSets(result){
      //console.log('PrepImportHeadersOptionSets',result)
      let vm = this
      let ImportSession = Object.assign({},result.ImportSession)
      let SingleResult = Object.assign({},result.SingleResult)
      let OriginalImportObject = Object.assign({},result.OriginalImportObject)
      let FailureObject = Object.assign({},result.FailureObject)
      //console.log('PrepImportHeadersOptionSets',ImportSession,SingleResult,OriginalImportObject,FailureObject,vm.OptionSetHeaders)
      let length = vm.OptionSetHeaders.length
      if(ImportSession.Status && !ImportSession.Status.ID){
         let status = this.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
         let option = status.Options.find(obj => obj.Name === ImportSession.Status)
         if(option){
           ImportSession.Status = option
           SingleResult.Status = option
           OriginalImportObject.Status = option
           FailureObject.Status = option
         }
      }
      return new Promise(function(resolve, reject) {      
        if(length === 0){
          resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
        }
        else{
          vm.OptionSetHeaders.map((header,index) => {          
            let optionfield = vm.CurrentEntity.AllFields.find(obj => obj.id === header.id)
            if(!optionfield){                
              FailureObject[header.Name] = 'Failed - No Matching Field Found'
              ImportSession.hasFailures = true
              let FailureInstance = {Type: 'No Matching Option', Name: header.Name}
              FailureObject.Failures.push(FailureInstance)
            } 
            else{
              let match = optionfield.Options.find(obj => obj.Name === ImportSession[header.id])
              if(match){
                ImportSession[header.id] = match
              }
              else{
                FailureObject[header.Name] = 'Failed - No Matching Option Found'
                ImportSession.hasFailures = true
                let FailureInstance = {Type: 'No Matching Field', Name: header.Name}
                FailureObject.Failures.push(FailureInstance)
              }
            }
            //console.log(ImportSession)
            if(index-1+2 === length){
              resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
            }
          })
        }      
      })
    }, 
    PrepImportHeadersLookups(result){
      //console.log('PrepImportHeadersLookups',result)
      let vm = this
      let ImportSession = Object.assign({},result.ImportSession)
      let SingleResult = Object.assign({},result.SingleResult)
      let OriginalImportObject = Object.assign({},result.OriginalImportObject)
      let FailureObject = Object.assign({},result.FailureObject)
      //console.log('PrepImportHeadersLookups',ImportSession,SingleResult,OriginalImportObject)
      let length = vm.LookupHeaders.length
      return new Promise(function(resolve, reject) {
        if(length === 0){
          resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
        }
        else{
          vm.LookupHeaders.map((header,index) => {          
            let GetObj = header.Array.find(obj => obj[header.LookupFieldName].trim() == ImportSession[header.id].trim())
            //console.log(ImportSession[header.id].trim(),GetObj)
            if(typeof GetObj === 'undefined'){
              FailureObject[header.Name] = 'Failed - No Matching Record Found'
              ImportSession.hasFailures = true
              let FailureInstance = {Type: 'No Matching Lookup Record', Name: header.Name}
              FailureObject.Failures.push(FailureInstance)
            }
            else{
              let matchobj = {id: GetObj.id,[header.LookupFieldName]: GetObj[header.LookupFieldName]}
              ImportSession[header.id] = matchobj
              ImportSession[header.id+'id'] = matchobj.id
              if(header.id === 'Owner' && header.RelatedBuildID === 'Users'){
              let user = vm.$store.state.UsersArray.find(obj => obj.id === matchobj.id) 
              if(user){
                ImportSession.Business_Unit = {Name: user.Business_Unit.Name,id: user.Business_Unit.id}
                ImportSession.Business_Unitid = user.Business_Unit.id
              }
              }
              else if(header.id === 'Warehouse' && header.RelatedBuildID === 'Warehouses'){
              let user = vm.$store.state.WarehousesArray.find(obj => obj.id === matchobj.id) 
              if(user){
                ImportSession.Business_Unit = {Name: user.Business_Unit.Name,id: user.Business_Unit.id}
                ImportSession.Business_Unitid = user.Business_Unit.id
                ImportSession.Owner = {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name}
                ImportSession.Ownerid = vm.userLoggedIn.id
                
              }
              }            
            }
            //console.log(ImportSession)
            if(index-1+2 === length){
              resolve({ImportSession: ImportSession,SingleResult: SingleResult, OriginalImportObject: OriginalImportObject, FailureObject: FailureObject})
            }
          }) 
        }      
      })
    }, 
    UpdateFiresStore(SingleResult, Index){
      let vm = this
      let ResultLength = this.CSVResult.length
      let OriginalImportObject = Object.assign({},SingleResult)                
                    
        let NameQuery = []
        let NameStringArray = SingleResult[this.CurrentEntity.Primary_Field_Name].trim()
        var lowertext = "";                
        var p;
          let buildup = ''
        //console.log(NameStringArray.length,NameStringArray)
          for (p = 0; p < NameStringArray.length; p++) {
              buildup = buildup+ NameStringArray[p]
                lowertext += buildup.toLowerCase() + ",";
                }                   
          NameQuery = lowertext.split(',')  
          NameQuery.length = NameQuery.length-1
                 
          NameQuery = NameQuery.concat(NameStringArray.split(' '))    
          NameQuery = NameQuery.map(entry => {
            return entry.toLowerCase()
          })

      let status = this.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
      let Status = status.DefaultOption
      let Status_Reason = status.DefaultLevel2Option              
      let ImportSession = {
        [this.CurrentEntity.Primary_Field_Name]: SingleResult[this.CurrentEntity.Primary_Field_Name].trim(),
        [this.CurrentEntity.Primary_Field_Name+'Query']: NameQuery,
        SearchQuery: NameQuery,
        Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
        Modified_Byid: this.userLoggedIn.id,
        Modified_On: new Date(), 
        ImportedRecord: true,
        Status: Status,
        Status_Reason: Status_Reason
        }
        let FailureObject = Object.assign({},ImportSession)
        FailureObject.Failures = []
        this.PrepImportHeadersDefault(ImportSession,SingleResult,OriginalImportObject,FailureObject).then(defheadres => {
          if(defheadres){
           vm.PrepImportHeadersOptionSets(defheadres).then(OSheadres => {
              if(OSheadres){
                vm.PrepImportHeadersLookups(OSheadres).then(LUheadres => {
                  if(LUheadres){
                    vm.RoundUpImport(LUheadres,ResultLength,Index)
                  }
                })
              }
            }) 
          }
          
        })        
    },
    UpdateFiresStoreReimport(SingleResult, Index){
      let vm = this
      let ResultLength = this.CSVResult.length
      let OriginalImportObject = Object.assign({},SingleResult)                
                    
        let NameQuery = []
        let NameStringArray = SingleResult[this.CurrentEntity.Primary_Field_Name].trim()
        var lowertext = "";                
        var p;
          let buildup = ''
        //console.log(NameStringArray.length,NameStringArray)
          for (p = 0; p < NameStringArray.length; p++) {
              buildup = buildup+ NameStringArray[p]
                lowertext += buildup.toLowerCase() + ",";
                }                   
          NameQuery = lowertext.split(',')  
          NameQuery.length = NameQuery.length-1
                 
          NameQuery = NameQuery.concat(NameStringArray.split(' '))    
          NameQuery = NameQuery.map(entry => {
            return entry.toLowerCase()
          })
      let status = this.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
      let Status = status.DefaultOption
      let Status_Reason = status.DefaultLevel2Option              
      let ImportSession = {
        [this.CurrentEntity.Primary_Field_Name]: SingleResult[this.CurrentEntity.Primary_Field_Name].trim(),
        [this.CurrentEntity.Primary_Field_Name+'Query']: NameQuery,
        SearchQuery: NameQuery,
        Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
        Modified_Byid: this.userLoggedIn.id,
        Modified_On: new Date(), 
        ImportedRecord: true,
        Status: Status,
        Status_Reason: Status_Reason
        }
        
        // this.Headers.map (header => {
        //   let prop = header.propvalue
        //   if(typeof SingleResult[prop] !== 'undefined'){
        //     let value = SingleResult[prop]
        //     if(header.Type === 'Date' && !header.HasTimeInput){
        //       ImportSession[prop] = this.DateFormatter(SingleResult[value])
        //     }
        //     else if(header.Type === 'Date' && header.HasTimeInput){
        //       ImportSession[prop] = this.DateFormatterwithTimeImport(value+'T'+SingleResult[prop+'_Time'])
        //     }
        //     else if(header.Type === 'Number Field'){
        //       let CommaTODot = SingleResult[value].split(',').join('.')
        //       ImportSession[prop] = Number(CommaTODot)
        //     }
        //     else{
        //       ImportSession[prop] = value
        //     }
            
        //   }
        // })
        let FailureObject = Object.assign({},ImportSession)
        FailureObject.Failures = []
        this.PrepImportHeadersDefault(ImportSession,SingleResult,OriginalImportObject,FailureObject,true).then(defheadres => {
          if(defheadres){
           vm.PrepImportHeadersOptionSets(defheadres).then(OSheadres => {
              if(OSheadres){
                vm.PrepImportHeadersLookups(OSheadres).then(LUheadres => {
                  if(LUheadres){
                    //console.log('LUheadres,ResultLength,Index',LUheadres,ResultLength,Index)
                    vm.RoundUpReImport(LUheadres,ResultLength,Index)
                  }
                })
              }
            }) 
          }
          
        }) 
        // let FailureObject = Object.assign({},ImportSession)
        // FailureObject.Failures = []
        // console.log(ImportSession)
        // if(ImportSession.hasFailures){
        // this.PartialFailuresArray.push(FailureObject)

        // }
        // else{
        //   this.SuccessfulImportsArray.push(OriginalImportObject)
        // }

        // this.NewImportsArray.push(OriginalImportObject)
        // console.log(this.NewImportsArray,this.TableCollectionRef)
    },
    DateFormatterwithTimeImport(date){
        if(date){
              let removeT = date.split('T').join(' ')
              let removeFWDslash = date.split('/').join('-')
              let yearstring = removeFWDslash.split('-')[0]
              let monthstring = removeFWDslash.split('-')[1]
              let daystring = removeFWDslash.split('-')[2].split('T')[0]
              let timestring = removeFWDslash.split('-')[2].split('T')[1]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              var hr = timestring.split(':')[0]
              var min = timestring.split(':')[1]
              var sec = timestring.split(':')[2]
              // if (min < 10) {
              //     min = "0" + min;
              // }
              var ampm = "am";
              //console.log(yearnumber, monthnumber-1, daynumber,removeFWDslash,timestring,hr,min,sec)
              if(sec){
                //console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec))
                return new Date(yearnumber, monthnumber-1, daynumber,hr,min,sec) 
              }
              else{
                //console.log(new Date(yearnumber, monthnumber-1, daynumber,hr,min))
                return new Date(yearnumber, monthnumber-1, daynumber,hr,min)
              }
              
              }
            else{
              return null
            }
      },
    DateFormatter(date){
      if(date){
        let removeT = date.split('T').join(' ')
        let removeFWDslash = date.split('/').join('-')
      let yearstring = removeFWDslash.split('-')[0]
        let monthstring = removeFWDslash.split('-')[1]
        let daystring = removeFWDslash.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
    },
    CSVConvertToJSON(text){
     
      this.NewImportsArray = []
      this.PartialFailuresArray = []
      
      let lines = text.split("\n")
      this.CheckDelimiter(lines)
      
    },
      ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      },      
      ToggleIsCalendarView(){
        if(this.IsVCardView){
          this.IsVCardView = false
        }
        this.IsCalendarView = !this.IsCalendarView
        this.STDTableView = !this.IsCalendarView
        
      },
      ViewItem (item) {
        if(this.DashboardView || this.IntranetView){
          let routeData = this.$router.resolve({name: 'account',params: {id: item.id}})
          window.open(routeData.href, '_blank')
        }
        else if(this.$route.meta && this.$route.meta.EntityCollectionSampler || this.MasterTableObj && this.MasterTableObj.HasSingleView){
          this.RoutetoEntitySingleBuilder(item)
        }
        else{
          //alert('this type'+this.CurrentEntity.id)
          if(this.StoreProductEntity && item.Business_Unitid){
            this.$router.push('/SalesOrder/'+this.CurrentEntity.id+'/'+item.id)
          }
          else{
            this.$router.push('/'+this.TableEntryPath+'/'+item.id)
          }
          
        }
        
      },
      RoutetoEntitySingleBuilder(item){
        //console.log('RoutetoEntitySingleBuilder(item)',item,this.MasterTableObj,this.$route)
        //console.log({ name: 'EntryViewer',query: {Parent: this.$route.query.Record,Record: item.id}, params: {slug: this.$route.params.slug,id: this.$route.params.id, EntityRecord: item,EntityConfigDB: '',EntityConfigSystemEntities: [] }})
      if(this.$route.name === 'TableViewer'){
        let routes = this.$router.getRoutes()
        //console.log(routes)
        if(this.$route.params.slug === 'Systems'){
          let newroute = routes.find(obj => obj.name === 'system')
          newroute.params = {id: this.$route.query.Record}
          newroute.query = { TableType: 'Operational_Entity',TableEntity: this.$route.query.TableEntity,RecordCollection: 'Sample_Records',Record: item.id}
          this.$router.push(newroute)
        }
        else{
          let newroute = routes.find(obj => obj.name === 'entity')
          newroute.params = {id: this.$route.query.Record}
          newroute.query = { TableType: 'Operational_Entity',RecordCollection: 'Sample_Records',Record: item.id}          
          this.$router.push(newroute)
        }
      }
      else if(this.$route.meta && this.$route.meta.RecordisSystem && this.MasterTableObj.EntityBuilder){
        this.$store.commit('SetSocialItemInteractMethodProp','') 
        this.$store.commit('SetSocialItemInteractMethod','ActivateSelectedElement')
        this.$router.replace({ name: "SystemConfig", query: { TableType: 'System_Entity',TableEntity: 'Entities',RecordCollection: 'entities',Record: item.id}})
        
      }
      else if(item.RouteQuery){
        let newroute = { path: this.$route.path, query: item.RouteQuery}
        //console.log(newroute)
        this.$router.push(newroute)
      }
      else if(this.$route.query && this.$route.query.Record){
        this.$router.push({ name: 'EntryViewer',query: {Parent: this.$route.query.Record,Record: item.id}, params: {slug: this.$route.params.slug,id: this.$route.params.id, EntityRecord: item,EntityConfigDB: '',EntityConfigSystemEntities: [] }})
      }
      // else if(!this.MasterTableObj.EntityBuilder){

      // }
      else{
        this.$router.replace({ name: 'EntryViewer',query: {Parent: this.$route.params.id,Record: item.id}, params: {slug: this.CurrentEntity.id,id: this.MasterTableObj.id, EntityRecord: item,EntityConfigDB: '',EntityConfigSystemEntities: [] }})
        // console.log(this.MasterTableObj,this.CurrentEntity)
      }
        
    },
      ActivateRelevantdialog(){
        this.close(true)
        if(this.tab){
         if(this.tab.EntitySource === 'System Routes'){
          this.dialog = !this.dialog
        }
        else if(this.tab.EntitySource === 'SubCollection'){
          this.SubColLookupDialogActive = true
        } 
        }
        else{
          this.dialog = !this.dialog
        }
        
      },
      
    
        Headerdragstart(head,headerindex,event){
            this.DraggingHeaderIndex = headerindex            
        },
        Headerdragfinish(head,headerindex,event){
            //this.Headers.splice(0,0,this.Headers.splice(currentprimaryindex,1)[0])
            this.Headers.splice(headerindex,0,this.Headers.splice(this.DraggingHeaderIndex,1)[0])  
            this.Headers.map((header,newindex) => {
                header.HeaderIndex = newindex
                //moved updates to SaveHeaderUpdates, so changes only occur either adding field, or bulk update
                // db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.id).collection('AllFields').doc(header.id).set(cleanheader).then(doc => {
                
                return header
            })          
            //console.log(this.Headers,this.id)
        },
        AddNewField(){
            let newfield = Object.assign({},this.NewField)
            newfield.Name = newfield.Name.split(' ').join('_')
            newfield.IsHeader = true
            newfield.HeaderIndex = this.Headers.length-1
            //console.log(newfield)  
            if(this.$route.meta && this.$route.meta.SystemConfigBuilder){
              //console.log(this.EntityCollectionRef)
              db.collection('SystemConfig').doc(this.$route.params.id).collection('entities').doc(this.id).collection('AllFields').doc(newfield.Name).set(newfield).then(doc => {
                //this.EntityCollectionRef.collection('entities').doc(this.id).collection('AllFields').doc(newfield.Name).set(newfield).then(doc => {
                  this.NewField = Object.assign({},this.DefaultNewField)
                this.RefreshComponent()
            })
            }
            else{
              if(this.NeedsRASync){        
                          
                    this.RAOperationalDB.collection('AllFields').doc(newfield.Name).set(newfield).then(radoc => {
                      this.EntityCollectionRef.collection('AllFields').doc(newfield.Name).set(newfield).then(doc => {
                        this.NewField = Object.assign({},this.DefaultNewField)
                        this.RefreshComponent()
                      })   
                    })
                  }
                  else{
                    this.EntityCollectionRef.collection('AllFields').doc(newfield.Name).set(newfield).then(doc => {
                      this.NewField = Object.assign({},this.DefaultNewField)
                        this.RefreshComponent()
                    })   
                  }
            }
            
        },
        SaveIconList(array){

        },
        RemovefromIconList(icon,index,array){
            array.splice(index,1)
        },
        deleteItem (item) {
        const index = this.Data.indexOf(item)
        confirm('Are you sure you want to delete this item?') && this.TableCollectionRef.doc(item.id).delete() && this.Data.splice(index, 1)
      },
        editItem (item) {
        this.editedIndex = this.Data.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.Headers.map(header => {
            //console.log(header,item)
            header.Response = this.editedItem[header.propvalue]
            if(header.HasDeepHierarchy && header.Levels && header.Levels[1]){
                header.Level1Response = this.editedItem[header.Levels[1].FieldName]
            }
            if(header.Type === 'Date'){
                if(this.editedItem[header.propvalue] && this.editedItem[header.propvalue].toDate){
                    let dateobject = this.editedItem[header.propvalue].toDate()
                    header.Response = format(dateobject,'yyyy-MM-dd')  
                    if(header.HasTimeInput){
                      let hours = dateobject.getHours()
                      let mins = dateobject.getMinutes()
                      if(hours === 0){
                        hours = '00'
                      }
                      if(mins === 0){
                        mins = '00'
                      }
                      header.TimeResponse = hours+':'+mins
                    }
                }
                
            }
        })
        this.dialog = true
      },
      
        async save(){
            let NewDataObj = {
              }
              
          if(this.editedIndex === -1){
            
              if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product'){
                NewDataObj.Available = 0
                NewDataObj.Backorder = 0
                 NewDataObj.Inbound = 0
                NewDataObj.On_Hand = 0
                NewDataObj.On_Order = 0
                NewDataObj.Received = 0
                NewDataObj.Picking = 0
                NewDataObj.Packing = 0
                NewDataObj.Dispatching = 0
                NewDataObj.Average_Price = 0
                NewDataObj.Total_Received = 0
                NewDataObj.Latest_Price = 0
                NewDataObj.Total_Cost = 0
                NewDataObj.Purchased_Counter = 0
                NewDataObj.Purchased_With = {}
              }
              if(this.StoreProductEntity){
                if(this.CurrentEntity.Precede_with_Quote){
                  //!E-COMMERCE PLUGIN
                  NewDataObj.Order_Progress = {ID: 1000001,Name: 'Preparing'}
                }
                else{
                  NewDataObj.Order_Progress = {ID: 1000001,Name: 'Preparing'}
                  //NewDataObj.Order_Progress = {ID: 1000003,Name: 'Approved'}
                }
                NewDataObj.Amount_Invoiced = 0
                NewDataObj.Amount_Paid = 0
                NewDataObj.Order_Total = 1
              }
              if(this.userLoggedIn){
                  NewDataObj.Business_Unit = this.userLoggedIn.Business_Unit
                  NewDataObj.Business_Unitid = this.userLoggedIn.Business_Unitid
                  NewDataObj.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                  NewDataObj.Created_Byid = this.userLoggedIn.id,
                  NewDataObj.Created_On = new Date()
                  NewDataObj.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                  NewDataObj.Modified_Byid = this.userLoggedIn.id,
                  NewDataObj.Modified_On = new Date()
              }
              else{
                  //do we assign "system" as createdby?
                  NewDataObj.Created_On = new Date()
                  NewDataObj.Modified_On = new Date()
              }
          }
              let SearchQuery = []
              NewDataObj.SearchQuery = []
              let length = this.Headers.length
              //console.log('this.Headers',length,this.Headers)
              this.Headers.map((header,headerindex) => {
                  if(header.Response){
                      NewDataObj[header.propvalue] = header.Response
                      if(header.Type === 'Lookup'){
                          let baseobj = {
                             [header.LookupFieldName]: NewDataObj[header.propvalue][header.LookupFieldName],
                             id: NewDataObj[header.propvalue].id
                          }
                          if(header.RelatedRecord !== 'Users' && header.RelatedBuildID !== 'Warehouses'){
                              NewDataObj[header.propvalue] = baseobj
                              NewDataObj[header.propvalue+'id'] = baseobj.id
                          }
                          else if(header.RelatedRecord === 'Users' && header.propvalue === 'Owner'){
                              NewDataObj[header.propvalue] = baseobj
                              NewDataObj[header.propvalue+'id'] = baseobj.id
                              //and here we need to add the BU of the owner, but how?
                              let ownerobj = this.$store.state.UsersArray.find(obj => obj.id === baseobj.id)
                              if(ownerobj){
                                NewDataObj.Business_Unit = ownerobj.Business_Unit
                                NewDataObj.Business_Unitid = ownerobj.Business_Unitid
                              }
                          }
                          else if(header.RelatedBuildID === 'Warehouses' && header.propvalue === 'Warehouse'){
                              NewDataObj[header.propvalue] = baseobj
                              NewDataObj[header.propvalue+'id'] = baseobj.id
                              //and here we need to add the BU of the owner, but how?
                              let whobj = this.$store.state.WarehousesArray.find(obj => obj.id === baseobj.id)
                              //console.log('whobj',whobj,this.$store.state.WarehousesArray)
                              if(whobj){
                                NewDataObj.Business_Unit = whobj.Business_Unit
                                NewDataObj.Business_Unitid = whobj.Business_Unitid
                                NewDataObj.Owner = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                                NewDataObj.Ownerid = this.userLoggedIn.id
                                
                              }
                          }
                          else{
                            NewDataObj[header.propvalue] = {
                                [header.LookupFieldName]: NewDataObj[header.propvalue][header.LookupFieldName],
                                id: NewDataObj[header.propvalue].id,                                
                            }
                            if(NewDataObj[header.propvalue] && NewDataObj[header.propvalue].Name && NewDataObj[header.propvalue].Surname){
                                  NewDataObj.Name = NewDataObj[header.propvalue].Name
                                  NewDataObj.Surname = NewDataObj[header.propvalue].Surname
                            }
                            NewDataObj[header.propvalue+'id'] = baseobj.id
                          }
                          if(header.MappedValues){
                              header.MappedValues.map(mapvalue => {
                                  if(mapvalue.InputField.Type !== 'Common Field' || mapvalue.InputField.Type === 'Common Field' && mapvalue.InputField.CommonFieldType !== 'Physical Address' && mapvalue.InputField.CommonFieldType !== 'Postal Address'){
                                      if(header.Response[mapvalue.InputField.Name]){
                                        NewDataObj[mapvalue.OutputField.Name] = header.Response[mapvalue.InputField.Name] 
                                        if(mapvalue.OutputField.Type === 'Lookup'){
                                              NewDataObj[mapvalue.OutputField.Name+'id'] = header.Response[mapvalue.InputField.Name+'id']
                                          }
                                      }                            }
                                  else if(mapvalue.InputField.Type === 'Common Field'){
                                      mapvalue.OutputField.FieldBreakdown.map((breakdown,breakdownindex) => {                                        
                                        let mapname = mapvalue.InputField.FieldBreakdown[breakdownindex].Name
                                          if(header.Response[mapname]){
                                          NewDataObj[breakdown.Name] = header.Response[mapname]   
                                          }                                    
                                      })
                                  }                                                        
                              })           
                          }
                          
                      }
                      else if(header.Type === 'Date' && !header.HasTimeInput){
                          NewDataObj[header.propvalue] = new Date(header.Response)
                      }
                      else if(header.Type === 'Date' && header.HasTimeInput){                       
                          NewDataObj[header.propvalue] = new Date(header.Response+' '+header.TimeResponse)
                          NewDataObj[header.propvalue+'TimeResponse'] = header.TimeResponse
                      }
                      else if(header.HasDeepHierarchy && header.Levels && header.Levels[1]){
                          NewDataObj[header.Levels[1].FieldName] = header.Level1Response
                      }
                      if(header.IsSearch || header.Primary){
                        //NewDataObj.SearchQuery = []
                        let PrimaryString = ''
                        if(header.Type === 'Single Line Text'){
                          PrimaryString = NewDataObj[header.propvalue].trim()
                        }
                        else if(header.Type === 'Option Set'){
                          PrimaryString = NewDataObj[header.propvalue].trim()
                        }
                        //console.log('PrimaryString','"PrimaryString"')
                        var lowertext = "";                
                        var p;
                        let buildup = ''
                        //console.log(PrimaryString.length,PrimaryString)
                        for (p = 0; p < PrimaryString.length; p++) {
                            buildup = buildup+ PrimaryString[p]
                            if(p === PrimaryString.length-1){
                              lowertext += buildup.toLowerCase();
                            }
                            else{
                              lowertext += buildup.toLowerCase() + ",";
                            }
                        }          
                        NewDataObj.SearchQuery = NewDataObj.SearchQuery.concat(NewDataObj[header.id].trim().split(' '))         
                        NewDataObj.SearchQuery = NewDataObj.SearchQuery.concat(lowertext.split(','))  
                        NewDataObj.SearchQuery = NewDataObj.SearchQuery.map(entry => {
                          return entry.toLowerCase()
                        })                      
                        //NewDataObj[header.id+'Query'].length = NewDataObj[header.id+'Query'].length-1
                      }
                      if(header.Primary){
                        NewDataObj[header.id+'Query'] = []
                        let PrimaryString = NewDataObj[header.propvalue].trim()
                        var lowertext = "";                
                        var p;
                        let buildup = ''
                        //console.log(PrimaryString.length,PrimaryString)
                        for (p = 0; p < PrimaryString.length; p++) {
                            buildup = buildup+ PrimaryString[p]
                            if(p === PrimaryString.length-1){
                              lowertext += buildup.toLowerCase();
                            }
                            else{
                              lowertext += buildup.toLowerCase() + ",";
                            }
                        }                   
                        NewDataObj[header.id+'Query'] = lowertext.split(',') 
                      }
                      if(this.editedIndex > -1){
                        //console.log(header.propvalue,NewDataObj[header.propvalue],NewDataObj)
                        if(typeof NewDataObj[header.propvalue] !== 'undefined'){  
                          if(header.id === 'Status'){
                            
                            this.TableCollectionRef.doc(this.editedItem.id).update({
                               Status: NewDataObj.Status,
                               Status_Reason: NewDataObj.Status_Reason,
                               State_Modified_Date: new Date()
                             })
                             if(this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Classified Listing' && this.editedItem.Combined_Title){
                                const functions = firebase.functions();
                                const UpdateRecordData = functions.httpsCallable('UpdateRecordData'); 
                                let payload = {
                                  collection: this.CurrentEntity.DisplayName.split(' ').join('').toLowerCase()+'listings',
                                  RecordID: this.editedItem.id,
                                  UpdatedValues: [
                                    {Prop: 'Status', Value: NewDataObj.Status,Prop: 'Status_Reason', Value: NewDataObj.Status_Reason}
                                  ],
                                  siteid: this.$store.state.ActiveSuiteid
                                }
                                UpdateRecordData(payload).then(result => {
                                   //console.log(result)
                                })
                             }
                          }
                          else{
                            
                            this.TableCollectionRef.doc(this.editedItem.id).update({
                               [header.propvalue]: NewDataObj[header.propvalue]
                             })
                             if(header.Primary){
                                this.TableCollectionRef.doc(this.editedItem.id).update({
                                  [header.id+'Query']: NewDataObj[header.id+'Query']
                                })
                             }
                             if(header.Type === 'Lookup' && header.propvalue !== 'Owner' && header.propvalue !== 'Warehouse'){
                                this.TableCollectionRef.doc(this.editedItem.id).update({
                                  [header.propvalue+'id']: NewDataObj[header.propvalue+'id']
                                })                               
                             }
                             else if(header.Type === 'Social Lookup' && header.propvalue !== 'Owner'){
                                this.TableCollectionRef.doc(this.editedItem.id).update({
                                  [header.propvalue+'id']: NewDataObj[header.propvalue+'id']
                                })                               
                             }
                             else if(header.Type === 'Lookup' && header.propvalue === 'Owner'){
                               this.TableCollectionRef.doc(this.editedItem.id).update({
                                  [header.propvalue+'id']: NewDataObj[header.propvalue+'id'],
                                  Business_Unit: NewDataObj.Business_Unit,
                                  Business_Unitid: NewDataObj.Business_Unitid,
                                })
                                NewDataObj.id = this.editedItem.id 
                                if(header.propvalue === 'Owner'){
                                  this.CreateLiaisonNotificationtoOwner(NewDataObj)
                                }                            
                             }
                             else if(header.Type === 'Lookup' && header.propvalue === 'Warehouse'){
                               this.TableCollectionRef.doc(this.editedItem.id).update({
                                  [header.propvalue+'id']: NewDataObj[header.propvalue+'id'],
                                  Business_Unit: NewDataObj.Business_Unit,
                                  Business_Unitid: NewDataObj.Business_Unitid,
                                  Owner: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
                                  Ownerid: this.userLoggedIn.id,
                                })
                                NewDataObj.id = this.editedItem.id 
                             }
                          }                         
                             
                           }
                      }
                  }
                  let vm = this//console.log(headerindex)
                  if(headerindex-1+2 === length){
                      if(this.editedIndex === -1){
                        let newdocid = ''
                        let ref = this.TableCollectionRef.doc()
                        if(this.MasterTableObj && this.MasterTableObj.ConfigValueasDocID && this.MasterTableObj.DocIDProp){
                          newdocid = NewDataObj[this.MasterTableObj.DocIDProp].split(' ').join('_').split("'").join('').split('"').join('').split('/').join('')
                        }
                        if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Plugins'){
                          newdocid = NewDataObj.Name.split(' ').join('_')
                        }
                        else if(this.StoreProductEntity){
                          const functions = firebase.functions();
                          
                          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
                          let payload = {
                            Docid: this.CurrentEntity.id+'_SO_Number',
                            Data: {},
                            siteid: this.$store.state.ActiveSuiteid
                          }
                          AssignAutoNumber(payload).then(result => {
                            newdocid = result.data
                            vm.FinalizeRecordLoading(newdocid,NewDataObj)
                          })
                        }
                        else if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Supplier'){
                          const functions = firebase.functions();
                          
                          const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
                          let payload = {
                            Docid: 'Store_Suppliers_Supplier_Code',
                            Data: NewDataObj,
                            siteid: vm.$store.state.ActiveSuiteid
                          }
                          AssignAutoNumber(payload).then(result => {
                            newdocid = result.data
                            NewDataObj.Supplier_Code = newdocid
                            vm.FinalizeRecordLoading(newdocid,NewDataObj)
                          })
                        }
                        else{
                          newdocid = ref.id
                        }
                        //console.log(NewDataObj)

                        //IN HERE WE SMACK THIS
                        
                      if(newdocid){
                        vm.FinalizeRecordLoading(newdocid,NewDataObj)
                      }
                      
                      }
                      else{
                          
                          this.close()
                          this.RefreshComponent()
                      // })
                      }
                  }
              })
              
        },
        FinalizeRecordLoading(newdocid,NewDataObj){
          this.TableCollectionRef.doc(newdocid).set(NewDataObj).then(doc => {
                        //console.log('ph setting')
                        if(this.FinalTableEntity.DefaultEntityProcess && this.editedIndex === -1){
                          let defaultprocess = this.FinalTableEntity.EntityProcesses.find(obj => obj.Number === this.FinalTableEntity.DefaultEntityProcess)
                          if(defaultprocess){
                            let processprop = 'RecordProcess'+defaultprocess.Number
                            this.TableCollectionRef.doc(newdocid).collection('processes').add(defaultprocess).then(processdoc => {
                              this.TableCollectionRef.doc(newdocid).update({
                                ActiveProcess: defaultprocess,
                                ActiveProcessDocid: processdoc.id,
                                [processprop]: processdoc.id,
                                stagename: defaultprocess.ProcessStages[0],
                                stagenameid: defaultprocess.ProcessStages[0].Number
                              })
                            })
                            if(NewDataObj.Owner){
                              NewDataObj.id = newdocid 
                              NewDataObj.ActiveProcess = defaultprocess
                              this.CreateLiaisonNotificationtoOwner(NewDataObj)
                            }
                            
                          }
                          else if(NewDataObj.Owner){
                              NewDataObj.id = newdocid 
                              this.CreateLiaisonNotificationtoOwner(NewDataObj)
                          }
                        }
                        else if(NewDataObj.Owner){
                              NewDataObj.id = newdocid 
                              this.CreateLiaisonNotificationtoOwner(NewDataObj)
                          }
                        if(this.$route.meta.EntityBuilder){
                          let configref = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.id)
                          this.AnalyzeandUpdateEntity(NewDataObj.Entity_Type.Name,this.TableCollectionRef.doc(newdocid),NewDataObj)
                        }
                        
                          //console.log('added new entry under '+newdocid)
                          this.close()
                          this.RefreshComponent()
                      })  
        },
        close(preponly){
            this.editedIndex = -1
            this.Headers.map(header => {
              if(this.IsRelatedTable && this.tab.RelationshipField.Name === header.Name || this.IsRelatedTable && this.tab.RelationshipField && header.MappedRelationshipField){

              } 
              else{
                header.Response = ''
              }
              if(header.DefaultOption){
                      header.Response = header.DefaultOption
                  }
            })
            this.editedItem = Object.assign(this.defaultItem)
            if(!preponly){
              this.dialog = false
            }
            this.Validating = false
        },
        CreateLiaisonNotificationtoOwner(record){
        let owneruser = record.Owner
        let NewComms = {
          description: this.userLoggedIn.Full_Name+' assigned the record to '+owneruser.Full_Name,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.FinalTableEntity.DisplayName,
          recordlocation: '/'+this.FinalTableEntity.SingleName.split(' ').join('')+'/'+record.id,
          topicid: this.FinalTableEntity.id,
        }
        if(record.ActiveProcess && record.ActiveProcess.Number){
            NewComms.ActiveProcessNumber = record.ActiveProcess.Number
        }
        //console.log(NewComms)
        this.SaveNewcomms(NewComms,record)  
    },
    SaveNewcomms(NewComms,record){
        let commscol = db.collection(this.FinalTableEntity.id.split('_').join('').toLowerCase()).doc(record.id).collection('InternalLiaison')
        //console.log(commscol)
        let owneruser = record.Owner
        let owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
        NewComms.recipientid = owner.id
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.FinalTableEntity.Primary_Field_Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.FinalTableEntity.Primary_Field_Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
            }) 
            
      },
        AnalyzeandUpdateEntity(type,configref,newobject){
          let fieldstoadd = []
          //we should be very careful on "fieldstoadd", considering all fields should strictly speaking be on template
          //on plenty of these we only missing "EntityRequired" when we add them on Ui but other than that should eb able to add them
          //that being said I believe almost true that every field on a template entity should be assumed EntityRequired...
          let defaultsubcols = []
          let recordprimaryfield = {Name: newobject.Primary_Field_Name.split(' ').join('_').split('(').join('').split(')').join('').split('/').join(''), DisplayName: newobject.Primary_Field_Name, Type: 'Single Line Text',Primary: true, Created: true,IsSearch: true,IsMandatory: true,IsHeader: true,HeaderIndex: 0}
          let CreatedBy = {Name: 'Created_By', DisplayName: 'Created By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
          let CreatedOn = {Name: 'Created_On', DisplayName: 'Created On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
          let ModifiedBy = {Name: 'Modified_By', DisplayName: 'Modified By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
          let ModifiedOn = {Name: 'Modified_On', DisplayName: 'Modified On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
          let State_Modified_Date = {Name: 'State_Modified_Date', DisplayName: 'State Modified Date', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
          let Status = this.DefaultStatusField
          let UserUnit = {Name: 'Business_Unit', DisplayName: 'Business Unit',Type: 'Lookup', Created: true, SystemRequired: true,LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'BusinessUnit', RelatedRecord: 'BusinessUnits',UsedonForm: true,UsedonCompForm: true, UsedonForm: true}
          fieldstoadd.push(recordprimaryfield)
          fieldstoadd.push(CreatedBy)
          fieldstoadd.push(CreatedOn)
          fieldstoadd.push(ModifiedBy)
          fieldstoadd.push(ModifiedOn)
          fieldstoadd.push(State_Modified_Date)
          //if(type !== 'Ticketing Table'){
          fieldstoadd.push(Status)
          //}
          fieldstoadd.push(UserUnit)
          //console.log(type,fieldstoadd,configref)
          // if(type === 'Product Order'){
          //   fieldstoadd.push({Name: 'User', DisplayName: 'User', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true,StrictlyLoggedIn: true,MappedField: {Primary: false,Name: 'User',LoggedInUser: true,}})
          //   fieldstoadd.push({Name: 'Name', DisplayName: 'Name', Type: 'Single Line Text',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true,StrictlyLoggedIn: true,MappedField: {Primary: false,Name: 'Name',LoggedInUser: true,}})
          //   fieldstoadd.push({Name: 'Surname', DisplayName: 'Surname', Type: 'Single Line Text',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true,StrictlyLoggedIn: true,MappedField: {Primary: false,Name: 'Name',LoggedInUser: true,}})
          //   fieldstoadd.push({Name: 'Email', DisplayName: 'Email', Type: 'Common Field',CommonFieldType: 'Email',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true,StrictlyLoggedIn: true,MappedField: {Primary: false,Name: 'Email',LoggedInUser: true,}})
          //   fieldstoadd.push({Name: 'Phone', DisplayName: 'Phone', Type: 'Common Field',CommonFieldType: 'Telephone Number',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true,StrictlyLoggedIn: true,MappedField: {Primary: false,Name: 'Mobile_Number',LoggedInUser: true,}})
          // }
          if(type === 'Classified Listing'){
              // Rememebr now, recordprimary HAS to be "title", don't need to "add" title as a field
              //you are WRONG. Title gets added, becuase the entit itself still has a primary field. 
              fieldstoadd.push({Type: 'Single Line Text', Name: 'Title', DisplayName: 'Title',text: 'Title',value: 'Title',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 1,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 2,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Number Field', Name: 'Price', DisplayName: 'Price',text: 'Price',value: 'Price',IsHeader: true,IsMandatory: true,HeaderIndex: 3,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Single File Upload', Name: 'Photo', DisplayName: 'Photo',text: 'Photo',value: 'Photo',FeaturedPhoto: true,EntityTypeRequired: true})
              //YET TO ADD GALLERY
          }
          else if(type === 'Ticketing Table'){
              //fieldstoadd.push(this.DefaultTicketingStatusField)
              fieldstoadd.push({Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: true,  EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 2,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Option Set', Name: 'Priority', DisplayName: 'Priority',text: 'Priority',value: 'Priority',IsHeader: true,IsMandatory: true,HeaderIndex: 3,Options: [{ID: 1000001, Name: 'Low'},{ID: 1000002, Name: 'Medium'},{ID: 1000003, Name: 'High'}],lastassignedinteger: 1000003,HasColors: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'Due_Date', DisplayName: 'Due Date',text: 'Due Date',value: 'Due_Date',IsHeader: true,IsMandatory: true,HeaderIndex: 4,EntityTypeRequired: true})
              //fieldstoadd.push({Type: 'Common Field',CommonFieldType: 'URL Link', Name: 'ViewURL', DisplayName: 'ViewURL',text: 'ViewURL',value: 'ViewURL',IsHeader: true,IsMandatory: true,HeaderIndex: 5,EntityTypeRequired: true})
              
          }
          else if(type === 'Activities'){
              //fieldstoadd.push(this.DefaultTicketingStatusField)
              fieldstoadd.push({Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsHeader: true,IsMandatory: true,HeaderIndex: 1, UsedonCompForm: false, UsedonForm: true,  EntityTypeRequired: true})
              fieldstoadd.push({Name: 'Business_Unit', DisplayName: 'Business Unit',text: 'Business Unit',value: 'Business_Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 2, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true})
              fieldstoadd.push({Type: 'Option Set', Name: 'Type', DisplayName: 'Type',text: 'Type',value: 'Type',IsHeader: true,IsMandatory: true,HeaderIndex: 3,Options: [{
                ID: 1000001,
                Name: 'Task'
              },
              {
                ID: 1000002,
                Name: 'Appointment'
              },
              {
                ID: 1000003,
                Name: 'Email'
              },
              {
                ID: 1000004,
                Name: 'Phone Call'
              }],lastassignedinteger: 1000003,HasColors: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 4,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Option Set', Name: 'Priority', DisplayName: 'Priority',text: 'Priority',value: 'Priority',IsHeader: true,IsMandatory: true,HeaderIndex: 5,Options: [{ID: 1000001, Name: 'Low'},{ID: 1000002, Name: 'Medium'},{ID: 1000003, Name: 'High'}],lastassignedinteger: 1000003,HasColors: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'Due_Date', DisplayName: 'Due Date',text: 'Due Date',value: 'Due_Date',IsHeader: true,IsMandatory: true,HeaderIndex: 6,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Boolean', Name: 'Has_Reminder', DisplayName: 'Has Reminder',text: 'Has Reminder',value: 'Has_Reminder',IsHeader: true,IsMandatory: true,HeaderIndex: 7,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'Completed_On', DisplayName: 'Completed On',text: 'Completed On',value: 'Completed_On',IsHeader: false,IsMandatory: false,EntityTypeRequired: true,UsedonForm: true})
              
              //fieldstoadd.push({Type: 'Common Field',CommonFieldType: 'URL Link', Name: 'ViewURL', DisplayName: 'ViewURL',text: 'ViewURL',value: 'ViewURL',IsHeader: true,IsMandatory: true,HeaderIndex: 5,EntityTypeRequired: true})
              
          }
          else if(type === 'Calendar'){
            //same here, primary field MUST be "title"
            //again is this not WRONG?. Ref Classified Listing
              fieldstoadd.push({Type: 'Date', Name: 'Start', DisplayName: 'Start',text: 'Start',value: 'Start',IsHeader: true,IsMandatory: true,HeaderIndex: 1,HasTimeInput: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'End', DisplayName: 'End',text: 'End',value: 'End',IsHeader: true,IsMandatory: true,HeaderIndex: 2,HasTimeInput: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 3,EntityTypeRequired: true})
          }
          else if(type === 'Booking Schedule'){
            
            //same here, primary field MUST be "title"
            //again is this not WRONG?. Ref Classified Listing
              fieldstoadd.push({Type: 'Option Set', Name: 'Type', DisplayName: 'Type',text: 'Type',value: 'Type',IsHeader: true,IsMandatory: true,HeaderIndex: 1,Options: [],lastassignedinteger: 1000000,HasColors: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'Start', DisplayName: 'Start',text: 'Start',value: 'Start',IsHeader: true,IsMandatory: true,HeaderIndex: 2,HasTimeInput: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Date', Name: 'End', DisplayName: 'End',text: 'End',value: 'End',IsHeader: true,IsMandatory: true,HeaderIndex: 3,HasTimeInput: true,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Description', DisplayName: 'Description',text: 'Description',value: 'Description',IsContentEditable: true,IsHeader: true,IsMandatory: true,HeaderIndex: 4,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Option Set', Name: 'RSVP_Advance_Type', DisplayName: 'RSVP Advance Type',text: 'RSVP Advance Type',value: 'RSVP_Advance_Type',IsHeader: true,IsMandatory: true,HeaderIndex: 5,Options: [{ID: 1000001,Name: 'Minutes'},{ID: 1000002,Name: 'Hours'},{ID: 1000003,Name: 'Days'}],lastassignedinteger: 1000003,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Option Set', Name: 'RSVP_Type', DisplayName: 'RSVP Type',text: 'RSVP Type',value: 'RSVP_Type',IsHeader: true,IsMandatory: true,HeaderIndex: 6,Options: [{ID: 1000001,Name: 'Minutes'},{ID: 1000002,Name: 'Hours'},{ID: 1000003,Name: 'Days'}],lastassignedinteger: 1000003,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Number Field', Name: 'RSVP_Advance_Amount', DisplayName: 'RSVP Advance Amount',text: 'RSVP Advance Amount',value: 'RSVP_Advance_Amount',IsHeader: true,IsMandatory: true,HeaderIndex: 7,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Number Field', Name: 'RSVP_Amount', DisplayName: 'RSVP Amount',text: 'RSVP Amount',value: 'RSVP_Amount',IsHeader: true,IsMandatory: true,HeaderIndex: 8,EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Number Field', Name: 'User_Limit', DisplayName: 'User Limit',text: 'User Limit',value: 'User_Limit',IsHeader: true,IsMandatory: true,HeaderIndex: 9,EntityTypeRequired: true})
              fieldstoadd.push({Name: 'Presenter', DisplayName: 'Presenter',text: 'Presenter',value: 'Presenter', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: true,HeaderIndex: 10, UsedonCompForm: false, UsedonForm: false,  EntityTypeRequired: true})
              fieldstoadd.push({Type: 'Single File Upload', Name: 'Image', DisplayName: 'Image',text: 'Image',value: 'Image',FeaturedPhoto: true,EntityTypeRequired: true})
              let userprimfield = {id: 'Full_Name',DisplayName: 'Full Name',Name: 'Full_Name',text: 'Full Name',value: 'Full_Name',propvalue: 'Full_Name',class: 'overline',Type: 'Single Line Text',Primary: true,EntityTypeRequired: true}
              fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Session_Outcome', DisplayName: 'Session Outcome',text: 'Session Outcome',value: 'Session_Outcome',IsContentEditable: true,EntityTypeRequired: true})
              defaultsubcols.push({
                id: 'BookedUsers',ConfigCollection: 'SubCollections',DisplayName: 'Booked Users',Name: 'BookedUsers',OptionType: 'SubCollection',SectionType: 'SubCollection',MultipleFileUploadType: 'SubCollection',
                HeadersInteger: 1000001,
                Headers:[userprimfield],
                Lookup_Once_Only: true,
                LookupBuilds: [
                  {
                    AllFields: [userprimfield],
                    Entity_Type: {ID: 1000004,Name: 'User Entity'},
                    LinkedFields: {Full_Name: userprimfield},
                    dataseticon: 'mdi-face',
                    identifier: 'Full_Name',
                    id: 'Users',
                    SubCollections: [],
                    recordprimaryfield: userprimfield,
                    single: 'User',
                    tempname: 'User',
                    temppluralname: 'Users'
                  }
                ],
                Type: 'Lookup'
              })
          }

          else if(type === 'Inventory Article') {
            fieldstoadd.push({
                            id: 'Product_Group',
                            Name: 'Product_Group',
                            DisplayName: 'Product Group',
                            ListPrimary: true,
                            LastLevel: 3,
                            Levels: [{id: 1,FieldName: 'Product_Group',name: 'Product Group',children: [{id: 2,FieldName: 'Product_Class',name: 'Product Class',children: [{id: 3,FieldName: 'Product_Type',name: 'Product Type'}]}]},{id: 2,FieldName: 'Product_Class',name: 'Product Class',children: [{id: 3,FieldName: 'Product_Type',name: 'Product Type'}]},{id: 3,FieldName: 'Product_Type',name: 'Product Type'}],
                            LevelTree: [{id: 1,FieldName: 'Product_Group',name: 'Product Group',children: [{id: 2,FieldName: 'Product_Class',name: 'Product Class',children: [{id: 3,FieldName: 'Product_Type',name: 'Product Type'}]}]}],
                            Type: 'Option Set',
                            Options: [],
                            lastassignedinteger: 1000000,
                            HasDeepHierarchy: true,
                            EntityTypeRequired: true
                        })
                      fieldstoadd.push({Type: 'Single File Upload', Name: 'Image', DisplayName: 'Image',text: 'Image',value: 'Image',FeaturedPhoto: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Multiple Lines Text', Name: 'Descriptive_Text', DisplayName: 'Descriptive Text',text: 'Descriptive Text',value: 'Descriptive_Text',IsContentEditable: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Average_Price', DisplayName: 'Average Price',text: 'Average Price',value: 'Average_Price',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Supply_Leadtime', DisplayName: 'Supply Leadtime',text: 'Supply Leadtime',value: 'Supply_Leadtime',EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Purchase_Leadtime', DisplayName: 'Purchase Leadtime',text: 'Purchase Leadtime',value: 'Purchase_Leadtime',EntityTypeRequired: true})
              
          }
          else if(type === 'Store Product') {
                      let costprice = {Type: 'Number Field', Name: 'Cost_Price', DisplayName: 'Cost Price',text: 'Cost Price',value: 'Cost_Price',EntityTypeRequired: true}
                      let markup = {Type: 'Number Field', Name: 'Markup', DisplayName: 'Markup',text: 'Markup',value: 'Markup',IsPercentage: true,EntityTypeRequired: true}
                      let storeprice = {Type: 'Calculated Field', Name: 'Store_Price', DisplayName: 'Store Price',text: 'Store Price',value: 'Store_Price',EntityTypeRequired: true,
                      FieldFormulas: [
                        {fieldName: costprice,hasoperation: true,input: 'field',integer: 0, operation: '+'},
                        {fieldName: markup,hasoperation: true,input: 'percentage field',integer: 0, operation: '='}
                      ]}
                      let discount = {Type: 'Number Field', Name: 'Discount', DisplayName: 'Discount',text: 'Discount',value: 'Discount',IsPercentage: true,EntityTypeRequired: true}
                      fieldstoadd.push(costprice)
                      fieldstoadd.push(discount)
                      fieldstoadd.push(markup)
                      fieldstoadd.push(storeprice)
                      fieldstoadd.push({Type: 'Calculated Field', Name: 'Store_Price', DisplayName: 'Store Price',text: 'Store Price',value: 'Store_Price',EntityTypeRequired: true,
                      FieldFormulas: [
                        {fieldName: storeprice,hasoperation: true,input: 'field',integer: 0, operation: '-'},
                        {fieldName: discount,hasoperation: true,input: 'percentage field',integer: 0, operation: '='}
                      ]})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Available', DisplayName: 'Available',text: 'Available',value: 'Available',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'On_Hand', DisplayName: 'On Hand',text: 'On Hand',value: 'On_Hand',ReadOnly: true,EntityTypeRequired: true})                      
                      fieldstoadd.push({Type: 'Number Field', Name: 'On_Order', DisplayName: 'On Order',text: 'On Order',value: 'On_Order',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Received', DisplayName: 'Received',text: 'Received',value: 'Received',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Picking', DisplayName: 'Picking',text: 'Picking',value: 'Picking',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Packing', DisplayName: 'Packing',text: 'Packing',value: 'Packing',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Dispatching', DisplayName: 'Dispatching',text: 'Dispatching',value: 'Dispatching',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Total_Received', DisplayName: 'Total Received',text: 'Total Received',value: 'Total_Received',ReadOnly: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Average_Price', DisplayName: 'Average Price',text: 'Average Price',value: 'Average_Price',ReadOnly: true,Iscurrency: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Latest_Price', DisplayName: 'Latest Price',text: 'Latest Price',value: 'Latest_Price',ReadOnly: true,Iscurrency: true,EntityTypeRequired: true})
                      fieldstoadd.push({Type: 'Number Field', Name: 'Total_Cost', DisplayName: 'Total Cost',text: 'Total Cost',value: 'Total_Cost',ReadOnly: true,Iscurrency: true,EntityTypeRequired: true})              
          }
          //Store Products - Brand only?
          //Scheduled Bookings - ??? Type, Calendar Std What else?
          //User Entity - ???
          //Business Unit Entity - ???


                fieldstoadd.map(field => {
                  configref.collection('AllFields').doc(field.Name).set(field)
                })
                defaultsubcols.map(subcol => {
                  configref.collection('SubCollections').doc(subcol.id).set(subcol)
                })
                configref.update({
                  RecordPrimaryField: recordprimaryfield,
                  SingleBuildTabs: [],
                })
        },
        ActivateExportDialog(array,headers){
          this.ExportDataArray = array.map(item => {
            delete item.Children
            delete item.headers
            return item
          })
          this.ExportHeaders = Array.from(headers)
          this.exportdialog = true
        },
        closeExport(){
          this.exportdialog = false
          this.ExportData = []
          this.ExportHeaders = []
        },
        ExportData(){
        this.exportToCsv('download.txt',this.ExportDataArray)
        },
        ExportDataforReimport(){
      this.exportToCsv('download.txt',this.ExportDataArray,true)
    },
      exportToCsv(filename, exportdata,forreimport) {
        const headers = this.ExportHeaders.map(header => {
          return header.value.split('.')[0]
        })
        const headerrows = [headers]
        if(forreimport === true){
          headers.push('id')
          let idobj = { text: 'id', value: 'id'}
          this.ExportHeaders.push(idobj)
          headers.push('id')
          this.ExportHeaders.push(idobj)
        }
        //console.log(this.ExportDataArray)
        //console.log(this.ExportHeaders)
        const datarows = exportdata.map(row => {
          row.csvdata =  this.ExportHeaders.map(header => {
            
            let headervalue = ''
            let firstprop = header.value.split('.')[0]
            let secondprop = header.value.split('.')[1]
            
            if(secondprop){
              
              if(row[firstprop]){
                if(row[firstprop][secondprop]){   
                  let thirdprop = row[firstprop][secondprop]
                              
                headervalue = row[firstprop][secondprop]
                }
              }
            }
            else{
              if(row[firstprop]){
                
              headervalue = row[firstprop]
              }
            }   
            if(header.text === 'Owner'){
              if(row.Owner){
                headervalue = row.Owner.Full_Name
              }
              
            }         
            return headervalue
          })
          return row
          }).map(row => {
            return row.csvdata
          })
        const rows = headerrows.concat(datarows)
        

        let csvContent = "data:text/txt;charset=utf-8," 
            + rows.map(e => e.join("|")).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", this.TableName+".txt");
            document.body.appendChild(link); // Required for FF

            link.click();
        },
    }
}
</script>

<style>
.dbtable .v-data-footer{
  font-family: "Roboto", Tahoma , sans-serif;
  font-weight: 300;
  font-size:  1em;
  letter-spacing: 0.1666666667;


}
/* .dbtable .v-data-footer .v-data-footer__pagination{ 
  position: relative;
  left:20%;
} */
/* .dbtable .v-data-footer .v-data-footer__icons-after {
  padding-left: 170px;

} */
.dbtable .v-data-footer .v-data-footer__icons-after .v-btn .v-btn__content{
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 5px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
}
/* .dbtable .v-data-footer .v-data-footer__icons-before {
 
  position: relative;
} */
.dbtable .v-data-footer .v-data-footer__icons-before .v-btn .v-btn__content{
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 5px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
  

}
</style>